/** @format */

// import moment from "moment";
import React, { useEffect, useState } from "react";
// import LoadingComponentRideModal from "../../LoadingComponentRideModal";
// import { SERVERADDRESS } from "../../../constants";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationById,
  sendQuotation,
  // undoInprogressAction,
  // undoStartAction,
  updateReservation2,
} from "../../../actions/reservationAction";
import moment from "moment";

const AdminSendQuoteModal = ({ sendQuote, openSendQuote, current, i }) => {
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;

  const { reservationDetails } = reservation;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    passengerId: "",
    name: "",
    amount: "",
    email: "",
    pickUpDate: "",
    pickUpTime: "",
    pickUpAddress: "",
    dropOffAddress: "",
  });
  // const dataLoaded = async () => {
  //   const id = current._id ? current._id : current.id;
  //   dispatch(
  //     getReservationById({
  //       _id: id,
  //     })
  //   );
  // };
  useEffect(() => {
    setValues({
      _id: current?._id ? current?._id : current?.id,
      name:
        current?.customerId?.firstName + " " + current?.customerId?.lastName,
      firstName: current?.customerId?.firstName,
      lastName: current?.customerId?.lastName,
      passengerId: current?.customerId?._id
        ? current?.customerId?._id
        : current?.customerId?.id,
      amount: current?.totalAmount,
      email: current?.customerId?.email,
      pickUpDate: moment(current?.pickUpDate?.substring(0, 10)).format(
        "MM/DD/YYYY"
      ),
      pickUpTime:
        parseInt(current.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : (parseInt(current.pickUpTime?.substring(0, 2)) > 12
              ? parseInt(current.pickUpTime?.substring(0, 2)) - 12
              : parseInt(current.pickUpTime?.substring(0, 2))) +
            ":" +
            current.pickUpTime?.substring(3, 5) +
            (parseInt(current?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      pickUpAddress: current?.pickUpAddress?.address,
      dropOffAddress: current?.dropOffAddress?.address,
      dispatcherId: auth.user.id,
    });
    // dataLoaded();
  }, [sendQuote, current]);
  // useEffect(() => {
  //   if (reservationDetails) {
  //     setValues(reservationDetails);
  //   }
  // }, [reservationDetails]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const sendQuotationViaEmail = async (event) => {
    event.preventDefault();
    const id = values._id ? values._id : values.id;
    dispatch(sendQuotation(values, openSendQuote));
  };
  return (
    <>
      <div
        className={`modal fade ${sendQuote ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openSendQuote(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Send Quote
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openSendQuote()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={sendQuotationViaEmail}>
              <div className="modal-body">
                {
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Name
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Your Name"
                            autoComplete="false"
                            value={values.name}
                            // onChange={handleChange("firstName")}
                            required
                            disabled
                            maxLength={35}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Email
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Your Email"
                            autoComplete="false"
                            value={values.email}
                            onChange={handleChange("email")}
                            required
                            disabled={current?.customerId?.email}
                            maxLength={35}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Amount
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Amount"
                            autoComplete="false"
                            value={values.amount}
                            // onChange={handleChange("firstName")}
                            required
                            disabled
                            maxLength={35}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Pickup Date and Time
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Pickup Date and Time"
                            autoComplete="false"
                            value={
                              values.pickUpDate + " @ " + values.pickUpTime
                            }
                            // onChange={handleChange("firstName")}
                            required
                            disabled
                            // maxLength={35}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Pickup Address
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Pickup Address"
                            autoComplete="false"
                            value={values.pickUpAddress}
                            // onChange={handleChange("firstName")}
                            required
                            disabled
                            // maxLength={35}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark-grey mb-12"
                            data-label=""
                            htmlFor="after_days"
                          >
                            Dropoff Address
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Pickup Address"
                            autoComplete="false"
                            value={values.dropOffAddress}
                            // onChange={handleChange("firstName")}
                            required
                            disabled
                            // maxLength={35}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  disabled={loading}
                  // onClick={() => handleUpdate()}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSendQuoteModal;
