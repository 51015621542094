/** @format */

import moment, { months } from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "chart.js/auto";
import {
  //   getAllCardsData,
  //   getAllCardsData2,
  getAllCardsData3,
  getAllGraphData,
} from "../../../actions/kpiActions";
import {
  allCompanyUsers,
  allDriversAvailableForRoutes,
  allEmployees,
  //   getAllActiveUser,
  //   getAllDriver,
  getAllDriverWithoutPagination,
  //   getAllInactiveUser,
  getAllStudents,
  //   getAllUsers,
  getRoutePassengersByCompanyId,
  getTracksByCompanyId,
  onlineUsers,
} from "../../../actions/userActions";
import {
  allBadges,
  getAllVehiclesByCompanyId,
} from "../../../actions/vehicleActions";
// import { getMailByUserId } from "../../../actions/mailActions";
import { getAllPermission } from "../../../actions/permissionActions";
import {
  //   getAllRoutesOfCompany,
  getAllRoutesOfCompanyV2,
} from "../../../actions/routeActions";
import {
  getAllReservationV2,
  // getReservationsScheduleV2,
} from "../../../actions/reservationAction";
import {
  getAllInvoicesActionV2,
  invoiceSearch,
} from "../../../actions/invoiceActions";
// import { NavLink } from "react-router-dom";

const AdminDashboardAnalysis = () => {
  const kpi = useSelector((state) => state.kpi);
  const { graphData } = kpi;
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  const cardsData2 = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      getAllCardsData3({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(getAllPermission({ companyId: auth.user.companyId.id }));
  };
  useEffect(() => {
    cardsData2();
  }, []);
  // console.log(kpi, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")

  // externalCompanyId ayega companyId ki jaga ? aur passengerwithoutpagination bh bula ln
  const passengerData = async (e) => {
    dispatch(getAllStudents({ companyId: auth.user.companyId.id }));
    dispatch(
      getRoutePassengersByCompanyId({ companyId: auth.user.companyId.id })
    );
  };
  const driverData = async (e) => {
    dispatch(
      getAllDriverWithoutPagination({ companyId: auth.user.companyId.id })
    );
  };
  const routeDriverData = async (e) => {
    dispatch(
      allDriversAvailableForRoutes({ companyId: auth.user.companyId.id })
    );
  };
  const allEmployeesData = async (e) => {
    const data = {
      companyId: auth.user.companyId.id,
      userId: auth.user.id,
    };
    if (users?.onlineUsers?.length) {
      data.exist = users?.onlineUsers;
    }
    dispatch(onlineUsers(data));
    dispatch(
      allEmployees({
        companyId: auth.user.companyId.id,
        userId: auth.user.id,
      })
    );
  };
  const allVehicleData = async (e) => {
    dispatch(getAllVehiclesByCompanyId({ companyId: auth.user.companyId.id }));
  };

  const routeData = async (e) => {
    dispatch(
      getAllRoutesOfCompanyV2({
        companyId: auth.user.companyId.id,
      })
    );
  };

  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  // Convert the formattedChicagoDate to a Date object
  const currentDate = new Date(formattedChicagoDate);

  // Function to get the start of the week (Sunday)
  function getStartOfWeek(date) {
    const day = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const diff = date.getDate() - day; // Move back to Sunday
    return new Date(date.setDate(diff));
  }

  // Get the start of this week and the past week
  const thisWeekStart = getStartOfWeek(new Date(currentDate));
  const pastWeekStart = new Date(thisWeekStart);
  pastWeekStart.setDate(thisWeekStart.getDate() - 7);

  // Calculate the last day of this week (Saturday)
  const thisWeekEnd = new Date(thisWeekStart);
  thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

  // Format dates to YYYY-MM-DD
  const pastWeekFirstDate = pastWeekStart.toISOString().split("T")[0];
  const thisWeekLastDate = thisWeekEnd.toISOString().split("T")[0];

  // console.log("Past Week:", pastWeekFirstDate);
  // console.log("This Week:", thisWeekLastDate);

  // const reservationData = async (e) => {
  //   const chicagoDateOptions = {
  //     timeZone: "America/Chicago",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };
  //   const chicagoDate = new Date().toLocaleDateString(
  //     "en-US",
  //     chicagoDateOptions
  //   );
  //   const parts = chicagoDate.split("/");
  //   const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  //   dispatch(
  //     getAllReservationV2({
  //       companyId: auth.user.companyId.id,
  //       today: formattedChicagoDate,
  //     })
  //   );
  // };

  const graphDataLoad = async (e) => {
    dispatch(
      getAllGraphData({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
        year: values.year ? values.year : "2024",
        from: values.from ? values.from : thisWeekLastDate,
        to: values.to ? values.to : pastWeekFirstDate,
      })
    );
  };

  // const getInvoices = async () => {
  //   dispatch(
  //     getAllInvoicesActionV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       weekFirst:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       weekLast:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       monthFirst: moment().startOf("month").format("YYYY-MM-DD"),
  //       monthLast: moment().endOf("month").format("YYYY-MM-DD"),
  //     })
  //   );
  //   dispatch(
  //     invoiceSearch({
  //       companyId: auth.user.companyId.id,
  //       from:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       to:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //     })
  //   );
  // };
  // const userDataa = async (e) => {
  //   dispatch(
  //     allCompanyUsers({
  //       companyId: auth?.user?.companyId?.id,
  //       // isClient: false
  //     })
  //   );
  // };
  // const trackDriverData = async (e) => {
  //   dispatch(
  //     getTracksByCompanyId({
  //       companyId: auth?.user?.companyId?.id,
  //     })
  //   );
  // };
  // const allBadgesData = async () => {
  //   dispatch(allBadges({ companyId: auth.user.companyId.id }));
  // };
  useEffect(() => {
    // reservationData();
    // driverData();
    // routeDriverData();
    // allVehicleData();
    // allEmployeesData();
    // passengerData();
    // routeData();
    // getInvoices();
    // userDataa();
    // trackDriverData();
    // allBadgesData();
    graphDataLoad();
  }, []);

  const Today = "Today";
  const Weekly = "Weekly";
  const Monthly = "Monthly";

  // useEffect(() => {
  //   // Bar chart
  //   const xValues = [
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //     "Sunday",
  //   ];
  //   const yValues = [55, 49, 44, 24, 15, 101, 41];
  //   const barColors = [
  //     "red",
  //     "green",
  //     "blue",
  //     "orange",
  //     "brown",
  //     "yellow",
  //     "pink",
  //   ];

  //   const ctx3 = document.getElementById("myChart3");
  //   let myChart3 = new Chart(ctx3, {
  //     type: "bar",
  //     data: {
  //       labels: xValues,
  //       datasets: [
  //         {
  //           backgroundColor: barColors,
  //           data: yValues,
  //         },
  //       ],
  //     },
  //     options: {
  //       legend: { display: false },
  //       title: {
  //         display: true,
  //         // text: "World Wine Production 2018",
  //       },
  //     },
  //   });

  //   return () => {
  //     myChart3.destroy();
  //   };
  // }, []);

  const [bookingGraph, setBookingGraph] = useState([
    { label: "Cancel", value: 0, color: "#b91d47" },
    { label: "No Show", value: 0, color: "#00aba9" },
    { label: "Not Started", value: 0, color: "#2b5797" },
    { label: "Active", value: 0, color: "#FFFF00" },
    { label: "Completed", value: 0, color: "#1e7145" },
  ]);

  const [period, setPeriod] = useState("Today");
  const [selectedDate, setSelectedDate] = useState("");
  const [chart, setChart] = useState(null);

  const getDataForPeriod = (period) => {
    switch (period) {
      case "Today":
        return {
          cancel: kpi?.graphData?.totalTodayOrderCancelCount,
          noShow: kpi?.graphData?.totalTodayOrderNoShowCount,
          notStarted: kpi?.graphData?.totalTodayOrderNotStartedCount,
          active: kpi?.graphData?.totalTodayOrderActiveCount,
          completed: kpi?.graphData?.totalTodayOrderCompletedCount,
        };
      case "Weekly":
        return {
          cancel: kpi?.graphData?.totalWeekOrderCancelCount,
          noShow: kpi?.graphData?.totalWeekOrderNoShowCount,
          notStarted: kpi?.graphData?.totalWeekOrderNotStartedCount,
          active: kpi?.graphData?.totalWeekOrderActiveCount,
          completed: kpi?.graphData?.totalWeekOrderCompletedCount,
        };
      case "Monthly":
        return {
          cancel: kpi?.graphData?.totalMonthOrderCancelCount,
          noShow: kpi?.graphData?.totalMonthOrderNoShowCount,
          notStarted: kpi?.graphData?.totalMonthOrderNotStartedCount,
          active: kpi?.graphData?.totalMonthOrderActiveCount,
          completed: kpi?.graphData?.totalMonthOrderCompletedCount,
        };
      default:
        return { cancel: 0, noShow: 0, notStarted: 0, active: 0, completed: 0 };
    }
  };
  // console.log(period, "period");

  useEffect(() => {
    const periodData = getDataForPeriod(period);
    const updatedData = [
      { label: "Cancel", value: periodData.cancel, color: "#b91d47" },
      { label: "No Show", value: periodData.noShow, color: "#00aba9" },
      { label: "Not Started", value: periodData.notStarted, color: "#2b5797" },
      { label: "Active", value: periodData.active, color: "#FFFF00" },
      { label: "Completed", value: periodData.completed, color: "#1e7145" },
    ];
    setBookingGraph(updatedData);
  }, [graphData, period]);

  useEffect(() => {
    const ctx = document.getElementById("myChart");
    if (chart) {
      chart.destroy();
    }
    const newChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: bookingGraph?.map((item) => item.label + " " + item.value),
        datasets: [
          {
            backgroundColor: bookingGraph?.map((item) => item.color),
            data: bookingGraph?.map((item) => item.value),
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: `${period} Booking Status`,
        },
      },
    });
    setChart(newChart);
  }, [bookingGraph]);

  const [selectedMonth, setSelectedMonth] = useState("previous");
  const [selectedDataType, setSelectedDataType] = useState("Completed");
  const [selectedDataTypeWeekly, setSelectedDataTypeWeekly] = useState("Total");
  const [chartData, setChartData] = useState({ xValues: [], datasets: [] });
  const [weeklyChartData, setWeeklyChartData] = useState({
    xValues: [],
    datasets: [],
  });
  const [lineChart, setLineChart] = useState(null);
  const [weeklyLineChart, setWeeklyLineChart] = useState(null);

  // const getDataForMonth = (month, dataType) => {
  //   const dataMap = {
  //     Completed: kpi?.graphData?.lineChartCompleted,
  //     "No Show": kpi?.graphData?.lineChartNoShow,
  //     Canceled: kpi?.graphData?.lineChartCancel,
  //   };
  //   const filteredData = dataMap[dataType]?.filter(
  //     (data) => data?.month === month
  //   );

  //   const xValues = filteredData?.map((data) => data.value);
  //   const yValues = filteredData?.map((data) => data.count);

  //   return { xValues, yValues };
  // };

  // useEffect(() => {
  //   const data = getDataForMonth(selectedMonth, selectedDataType);
  //   const data2 = getDataForMonth("current", selectedDataType);
  //   setChartData({
  //     xValues: data.xValues,
  //     datasets: [
  //       {
  //         data: data.yValues,
  //         borderColor: "blue",
  //         fill: false,
  //         label: "Previous Month",
  //       },
  //       {
  //         data: data2.yValues,
  //         borderColor: "green",
  //         fill: false,
  //         label: "Current Month",
  //       },
  //     ],
  //   });
  // }, [graphData, selectedMonth, selectedDataType]);

  // useEffect(() => {
  //   const ctx = document.getElementById("myChart2");
  //   if (lineChart) {
  //     lineChart.destroy();
  //   }
  //   const newLineChart = new Chart(ctx, {
  //     type: "line",
  //     data: {
  //       labels: chartData.xValues,
  //       datasets: chartData.datasets,
  //     },
  //     options: {
  //       legend: { display: true },
  //     },
  //   });
  //   setLineChart(newLineChart);
  // }, [chartData]);

  const getDataForWeek = (weekType, dataType) => {
    const dataMap = {
      Completed: {
        LastWeek: kpi?.graphData?.lineChartLastWeek,
        ThisWeek: kpi?.graphData?.lineChartThisWeek,
        Avg: kpi?.graphData?.lineChartAvg,
      },
      "No Show": {
        LastWeek: kpi?.graphData?.lineChartLastWeek,
        ThisWeek: kpi?.graphData?.lineChartThisWeek,
        Avg: kpi?.graphData?.lineChartAvg,
      },
      Canceled: {
        LastWeek: kpi?.graphData?.lineChartLastWeek,
        ThisWeek: kpi?.graphData?.lineChartThisWeek,
        Avg: kpi?.graphData?.lineChartAvg,
      },
      Total: {
        LastWeek: kpi?.graphData?.lineChartLastWeek,
        ThisWeek: kpi?.graphData?.lineChartThisWeek,
        Avg: kpi?.graphData?.lineChartAvg,
      },
    };

    const dayMap = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday",
    };
    const weekData = dataMap[dataType][weekType]?.map((data) => ({
      dayOfWeek: data._id.dayOfWeek,
      count:
        dataType === "Total"
          ? //  weekType === "ThisWeek"
            // ? Math.round(kpi?.cardsData2?.totalTodayOrderCount)
            // : Math.round(data.completed + data.noShow + data.canceled)
            weekType === "Avg"
            ? Math.round(data.completed + data.noShow + data.canceled)
            : Math.round(data.total)
          : dataType === "No Show"
          ? Math.round(data.noShow)
          : Math.round(data[dataType.toLowerCase()]),
    }));

    const xValues = weekData?.map(
      (data) => dayMap[data.dayOfWeek] || `Day ${data.dayOfWeek}`
    );
    const yValues = weekData?.map((data) => data.count);

    return { xValues, yValues };
  };

  useEffect(() => {
    const lastWeekData = getDataForWeek("LastWeek", selectedDataTypeWeekly);
    const thisWeekData = getDataForWeek("ThisWeek", selectedDataTypeWeekly);
    const avgWeekData = getDataForWeek("Avg", selectedDataTypeWeekly);

    setWeeklyChartData({
      xValues: lastWeekData.xValues,
      datasets: [
        {
          data: lastWeekData.yValues,
          borderColor: "blue",
          fill: false,
          label: "Last Week",
        },
        {
          data: thisWeekData.yValues,
          borderColor: "green",
          fill: false,
          label: "This Week",
        },
        {
          data: avgWeekData.yValues,
          borderColor: "orange",
          fill: false,
          label: "Average",
        },
      ],
    });
  }, [graphData, selectedDataTypeWeekly]);

  useEffect(() => {
    const ctx = document.getElementById("myChartWeekly");
    if (weeklyLineChart) {
      weeklyLineChart.destroy();
    }
    const newLineChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: weeklyChartData.xValues,
        datasets: weeklyChartData.datasets,
      },
      options: {
        legend: { display: true },
        scales: {
          x: { title: { display: true, text: "Days of Week" } },
          y: { title: { display: true, text: "Count" } },
        },
      },
    });
    setWeeklyLineChart(newLineChart);
  }, [weeklyChartData]);

  const [chartData4, setChartData4] = useState({
    xValues: [],
    yValuesCompleted: [],
    yValuesNoShow: [],
    yValuesCanceled: [],
  });

  useEffect(() => {
    // Update the state with the data from kpi.graph.barChart
    setChartData4({
      xValues: kpi?.graphData?.barChart?.map((data) => data.month),
      yValuesCompleted: kpi?.graphData?.barChart?.map((data) => data.completed),
      yValuesNoShow: kpi?.graphData?.barChart?.map((data) => data.noShow),
      yValuesCanceled: kpi?.graphData?.barChart?.map((data) => data.canceled),
    });
  }, [kpi, graphData]);
  // console.log(chartData4, "chartData4");

  useEffect(() => {
    // Get the canvas element
    const ctx = document.getElementById("weeklyOrdersChart");

    // Create the chart
    const myChart4 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: chartData4.xValues,
        datasets: [
          {
            label: "Completed Orders",
            backgroundColor: "green",
            data: chartData4.yValuesCompleted,
          },
          {
            label: "Canceled Orders",
            backgroundColor: "red",
            data: chartData4.yValuesCanceled,
          },
          {
            label: "No-Show Orders",
            backgroundColor: "yellow",
            data: chartData4.yValuesNoShow,
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: "Yearly Orders",
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    // Cleanup function to destroy the chart instance when the component unmounts
    return () => {
      myChart4.destroy();
    };
  }, [chartData4]);

  const [chartData3, setChartData3] = useState({
    currentMonth: {
      completed: kpi?.graphData?.totalMonthOrderCompletedCount,
      noShow: kpi?.graphData?.totalMonthOrderNoShowCount,
      cancel: kpi?.graphData?.totalMonthOrderCancelCount,
    },
    previousMonth: {
      completed: kpi?.graphData?.totalPrevMonthOrderCompletedCount,
      noShow: kpi?.graphData?.totalPrevMonthOrderNoShowCount,
      cancel: kpi?.graphData?.totalPrevMonthOrderCancelCount,
    },
    totalPrevMonthOrderCount: kpi?.graphData?.totalPrevMonthOrderCount,
    totalMonthOrderCount: kpi?.graphData?.totalMonthOrderCount,
  });

  useEffect(() => {
    setChartData3({
      currentMonth: {
        completed: kpi?.graphData?.totalMonthOrderCompletedCount,
        noShow: kpi?.graphData?.totalMonthOrderNoShowCount,
        cancel: kpi?.graphData?.totalMonthOrderCancelCount,
      },
      previousMonth: {
        completed: kpi?.graphData?.totalPrevMonthOrderCompletedCount,
        noShow: kpi?.graphData?.totalPrevMonthOrderNoShowCount,
        cancel: kpi?.graphData?.totalPrevMonthOrderCancelCount,
      },
      totalPrevMonthOrderCount: kpi?.graphData?.totalPrevMonthOrderCount,
      totalMonthOrderCount: kpi?.graphData?.totalMonthOrderCount,
    });
  }, [graphData]);

  // useEffect(() => {
  //   const ctx = document.getElementById("myRadarChart").getContext("2d");
  //   const suggestedMax = Math.max(
  //     // chartData3.totalPrevMonthOrderCount,
  //     // chartData3.totalMonthOrderCount
  //     chartData3.currentMonth.completed,
  //     chartData3.currentMonth.noShow,
  //     chartData3.currentMonth.cancel,
  //     chartData3.previousMonth.completed,
  //     chartData3.previousMonth.noShow,
  //     chartData3.previousMonth.cancel
  //   );
  //   // console.log(suggestedMax, "suggestedMax ");

  //   const rChart = new Chart(ctx, {
  //     type: "radar",
  //     data: {
  //       labels: ["Completed", "No Show", "Canceled"],
  //       datasets: [
  //         {
  //           label: "Current Month",
  //           data: [
  //             chartData3.currentMonth.completed,
  //             chartData3.currentMonth.noShow,
  //             chartData3.currentMonth.cancel,
  //           ],
  //           fill: true,
  //           backgroundColor: "rgba(255, 99, 132, 0.2)",
  //           borderColor: "rgb(255, 99, 132)",
  //           pointBackgroundColor: "rgb(255, 99, 132)",
  //           pointBorderColor: "#fff",
  //           pointHoverBackgroundColor: "#fff",
  //           pointHoverBorderColor: "rgb(255, 99, 132)",
  //         },
  //         {
  //           label: "Previous Month",
  //           data: [
  //             chartData3.previousMonth.completed,
  //             chartData3.previousMonth.noShow,
  //             chartData3.previousMonth.cancel,
  //           ],
  //           fill: true,
  //           backgroundColor: "rgba(54, 162, 235, 0.2)",
  //           borderColor: "rgb(54, 162, 235)",
  //           pointBackgroundColor: "rgb(54, 162, 235)",
  //           pointBorderColor: "#fff",
  //           pointHoverBackgroundColor: "#fff",
  //           pointHoverBorderColor: "rgb(54, 162, 235)",
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         r: {
  //           angleLines: {
  //             display: true,
  //           },
  //           suggestedMin: 0,
  //           suggestedMax: suggestedMax,
  //         },
  //       },
  //     },
  //   });
  //   return () => {
  //     rChart.destroy();
  //   };
  // }, [chartData3]);
  const [values, setValues] = useState({
    today: formattedChicagoDate,
    year: "2024",
    // from: "",
    // to: "",
    from: thisWeekLastDate,
    to: pastWeekFirstDate,
  });

  const dateData = {
    companyId: auth.user.companyId.id,
    today: values.today,
    year: values.year,
    from: values.from,
    to: values.to,
  };
  // console.log(dateData, "dateData");

  // const handleChange = (fieldName) => (event) => {
  //   setValues({
  //     ...values,
  //     [fieldName]: event.target.value,
  //   });
  //   for (let key of Object.keys(dateData)) {
  //     if (dateData[key] === "") {
  //       delete dateData[key];
  //     }
  //   }
  //   dispatch(getAllGraphData(dateData));
  // };

  const handleChange = (fieldName) => (event) => {
    const newValue = event.target.value;

    setValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [fieldName]: newValue,
      };

      // Construct dateData with updated values
      const updatedDateData = {
        companyId: auth.user.companyId.id,
        today: updatedValues.today,
        year: updatedValues.year,
        from: updatedValues.from,
        to: updatedValues.to,
      };

      // Remove empty fields
      for (let key in updatedDateData) {
        if (!updatedDateData[key]) {
          delete updatedDateData[key];
        }
      }

      // Dispatch the action with the updated data
      dispatch(getAllGraphData(updatedDateData));
      return updatedValues;
    });
  };

  return (
    <>
      <div className="my-4">
        {/* <div className="row"> */}
        <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
          <div className="row">
            {/* <div className="col-md-8 mb-4">
              <div className="b-shadow-4 rounded p-2 bg-col-new">
                <div
                  className="card-body-new-2 p-2 clearfix"
                  style={{ minHeight: "240px" }}
                >
                  <div className="driver-text mb-3">
                    <div className="card-header card-headertext-blue h5 f-21 font-weight-bold">
                      Monthly Overview
                      <select
                        name="leads-table_length"
                        aria-controls="leads-table"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        autoComplete="false"
                        value={selectedDataType}
                        onChange={(e) => setSelectedDataType(e.target.value)}
                      >
                        {["Completed", "No Show", "Canceled"].map(
                          (current, i) => (
                            <option key={i} value={current}>
                              {current.charAt(0).toUpperCase() +
                                current.slice(1)}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <canvas id="myChart2" className=""></canvas>
                </div>
              </div>
            </div> */}

            <div className="col-md-6 mb-4">
              <div className="b-shadow-4 rounded p-2 bg-col-new">
                <div
                  className="card-body-new-2 p-2 clearfix"
                  style={{ minHeight: "240px" }}
                >
                  <div className="driver-text mb-3">
                    <div className="card-header card-headertext-blue h5 f-21 font-weight-bold">
                      Weekly Overview
                      <div className="d-flex">
                        <select
                          name="leads-table_length"
                          aria-controls="leads-table"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          autoComplete="false"
                          value={selectedDataTypeWeekly}
                          onChange={(e) =>
                            setSelectedDataTypeWeekly(e.target.value)
                          }
                        >
                          {["Total", "Completed", "No Show", "Canceled"].map(
                            (current, i) => (
                              <option key={i} value={current}>
                                {current.charAt(0).toUpperCase() +
                                  current.slice(1)}
                              </option>
                            )
                          )}
                        </select>
                        <input
                          type="date"
                          className="form-control height-35 f-14 ml-1"
                          autoComplete="off"
                          value={values.to}
                          onChange={handleChange("to")}
                        />
                        <input
                          type="date"
                          className="form-control height-35 f-14 ml-1"
                          autoComplete="off"
                          value={values.from}
                          onChange={handleChange("from")}
                        />
                      </div>
                    </div>
                  </div>
                  <canvas id="myChartWeekly" className=""></canvas>
                </div>
              </div>
            </div>

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col-md-6 mb-4" style={{ display: "flex" }}>
                  <div className="b-shadow-4 rounded p-2 bg-col-new">
                    <div
                      className="card-body-new-2 p-2 clearfix"
                      style={{ minHeight: "240px" }}
                    >
                      <div className="driver-text mb-3">
                        <div className="card-header card-headertext-blue h5 f-21 font-weight-bold">
                          Current - Previous Month Booking Comparison
                        </div>
                      </div>
                      <canvas
                        id="myRadarChart"
                        className="chart-canvas-radar"
                        // width="400"
                        // height="400"
                      ></canvas>
                    </div>
                  </div>
                </div> */}

            <div className="col-md-6 mb-3" style={{ height: "45vh" }}>
              <div className="b-shadow-4 rounded p-2 bg-col-new">
                <div
                  className="card-body-new-2 p-2 clearfix"
                  // style={{ minHeight: "240px" }}
                >
                  <div className="driver-text mb-3">
                    <div className="card-header card-headertext-blue h5 f-21 font-weight-bold">
                      Yearly Bookings
                      <select
                        name="leads-table_length"
                        aria-controls="leads-table"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        autoComplete="false"
                        value={values.year}
                        onChange={handleChange("year")}
                      >
                        {[
                          "2023", // Current year
                          "2024", // Last year
                          "2025",
                        ].map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <canvas
                    id="weeklyOrdersChart"
                    className="chart-canvas-bar"
                  ></canvas>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="b-shadow-4 rounded p-2 bg-col-new">
                <div
                  className="card-body-new-2 p-2 clearfix"
                  // style={{ minHeight: "240px" }}
                >
                  <div className="driver-text mb-4">
                    <div className="card-header card-headertext-blue h5 f-21 font-weight-bold">
                      Booking:
                      <div className="d-flex">
                        <select
                          name="leads-table_length"
                          aria-controls="leads-table"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          autoComplete="false"
                          value={period}
                          onChange={(e) => setPeriod(e.target.value)}
                        >
                          {["Today", "Weekly", "Monthly"].map((current, i) => (
                            <option key={i} value={current}>
                              {current}
                            </option>
                          ))}
                        </select>
                        <input
                          type="date"
                          className="form-control height-35 f-14 ml-1"
                          autoComplete="off"
                          value={values.today}
                          onChange={handleChange("today")}
                        />
                      </div>
                    </div>
                  </div>
                  <canvas
                    id="myChart"
                    className="chart-canvas-pie"
                    // style={{ width: "100%", maxWidth: "600px" }}
                  ></canvas>
                </div>
              </div>
            </div>
            {/* </div> */}

            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboardAnalysis;
