/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExternalCompanies } from "../../../actions/companyAction";
import {
  addClient,
  addStudentAction,
  addStudentActionFromBooking,
} from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../../../components/adminComponents/adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import Select from "react-select";

const AddStudentModal = ({
  showDropDown,
  openDropDown,
  setSelectedStudent,
  values,
  setValues,
  booking,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const { loading } = students;

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values2, setValues2] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isRouteUser: false,
    // workPhone: '',
    // otherPhone: '',
    // clientWorkInformation: '',
    address: "",
    // state: '',
    city: "",
    zip: "",
    type: "",
    notes: "",
    externalCompanyId: "",
    facility: "",
    daysOfWeek: [
      { day: 1, dayName: "Monday", active: true },
      { day: 2, dayName: "Tuesday", active: true },
      { day: 3, dayName: "Wednesday", active: true },
      { day: 4, dayName: "Thursday", active: true },
      { day: 5, dayName: "Friday", active: true },
      { day: 6, dayName: "Saturday", active: false },
      { day: 7, dayName: "Sunday", active: false },
    ],
    standingOrder: false,
  });

  const dispatch = useDispatch();

  const studentData = {
    companyId: auth.user.companyId.id,
    firstName: values2.firstName,
    lastName: values2.lastName,
    email: values2.email,
    phone: values2.phone,
    notes: values2.notes,
    isRouteUser: false,
    address: values2.address,
    // state: values2.state,
    city: values2.city,
    zip: values2.zip,
    facility: values2.facility,
    standingOrder: values.standingOrder,
    daysOfWeek: values.daysOfWeek,
    // isCompany: values2.type === "Company" ? true : false,
    // externalCompanyId: values2.type === "Company" ? selectedCompanyId : "",
  };
  // console.log(booking, "booking");
  const findExternalCompany = (id) => {
    const found = facility.find((x) => x.id === id);
    return found.externalCompanyId;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = { ...studentData };
    obj.isCompany = values.facility ? true : false;
    obj.externalCompanyId = values.facility
      ? findExternalCompany(values.facility)
      : "";
    for (let key of Object.keys(obj)) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    if (booking) {
      dispatch(
        addStudentActionFromBooking(obj, values, setValues, openDropDown)
      );
    } else {
      dispatch(addStudentAction(obj, successHandler));
    }
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(addStudentAction(studentData, successHandler));
  // };
  const successHandler = (studentId) => {
    setSelectedStudent((prev) => ({ ...prev, studentId }));
    openDropDown();
  };

  const handleChange = (fieldName) => (event) => {
    setValues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };

  const handleDayToggle = (index) => {
    setValues2({
      ...values2,
      daysOfWeek: values2.daysOfWeek.map((day, dayIndex) =>
        dayIndex === index ? { ...day, active: !day.active } : day
      ),
    });
  };

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues2({
      ...values2,
      [fieldName]: formattedValue,
    });
  };

  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setValues2({
  //         ...values2,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setValues2({
  //         ...values2,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setValues2({
  //         ...values2,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setValues2({
  //       ...values2,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <form onSubmit={handleSubmit}>
            <div className="modal-content fix-modal">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Add Passenger
                </h5>
                <button
                  type="button"
                  id="openDropDown2Button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="fName"
                      >
                        First Name
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        id="fName"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your First Name"
                        autoComplete="false"
                        value={values2.firstName}
                        onChange={handleChange("firstName")}
                        required
                        maxLength={35}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="lName"
                      >
                        Last Name
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        id="lName"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Last Name"
                        // name="lName"
                        autoComplete="false"
                        value={values2.lastName}
                        onChange={handleChange("lastName")}
                        required
                        maxLength={35}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="email"
                      >
                        Email
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <input
                        type="Email"
                        id="email"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Email"
                        autoComplete="false"
                        value={values2.email}
                        onChange={handleChange("email")}
                        // required
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="phone"
                      >
                        Phone
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      {/* <input
                        type="tel"
                        className="form-control height-35 f-14"
                        minLength="14"
                        maxLength="14"
                        // required
                        onKeyDown={(e) => {
                          if (e.ctrlKey && e.code === "KeyV") {
                            return;
                          }
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values2.phone.length === 6
                          ) {
                            setValues2({
                              ...values2,
                              phone: values2.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values2.phone.length === 2
                          ) {
                            setValues2({
                              ...values2,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values2.phone.length === 10
                          ) {
                            setValues2({
                              ...values2,
                              phone: values2.phone.substring(0, 9),
                            });
                          }
                        }}
                        name="phone"
                        value={values2.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123)-456-7890"
                      /> */}
                      <input
                        type="tel"
                        id="phone"
                        className="form-control height-35 f-14"
                        name="phone"
                        value={values2.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123)456-7890"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Facility
                      </label>
                      {/* <input
                        type="Facility"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Facility"
                        autoComplete="false"
                        value={values2.facility}
                        onChange={handleChange("facility")}
                      /> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={""}
                        value={facility
                          ?.map((x) => ({
                            label: `${x.name}`,
                            value: x.id,
                          }))
                          .find((e) => e.value === values?.facility)}
                        isDisabled={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        required={true}
                        name="facility"
                        id="facility"
                        // onChange={handleChange("facility")}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            facility: e.value,
                          });
                          setValues2({
                            ...values2,
                            facility: e.value,
                          });
                        }}
                        options={facility?.map((x) => ({
                          label: `${x.name}`,
                          value: x.id,
                        }))}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group my-3 ">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Select School
                          </label>
                          <sup className="text-danger f-12">*</sup>
                        </div>
                        <div>
                          <button
                            className="bg-button"
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            onClick={() => setShowModal(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <select
                        className="form-control height-35 f-14"
                        name="school"
                        onChange={(e) => setSelectedCompanyId(e.target.value)}
                        value={selectedCompanyId}
                        required
                  
                      >
                        <option value="">Select School</option>
                        {externalCompanies.list.map((x) => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="form-group my-3 ">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="serviceType"
                      >
                        {" "}
                        Service Type
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <select
                        id="serviceType"
                        name="serviceType"
                        className="form-control height-35 f-14"
                        onChange={handleChange("type")}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="Individual">Individual</option>
                        <option value="Company">Payer Name/Company</option>
                      </select>
                    </div>
                  </div>

                  {values2.type === "Company" && (
                    <div className="col-md-6">
                      <div className="form-group my-3 ">
                        <div className="d-flex justify-content-between">
                          <div className=" ">
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="payerNameOrCompany"
                            >
                              {" "}
                              Payer Name/Company
                            </label>
                            <sup className="text-danger f-12">*</sup>
                          </div>
                          <div>
                            <button
                              className="bg-button"
                              type="button"
                              id="payerNameOrCompany"
                              name="payerNameOrCompany"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                              onClick={() => setShowModal(true)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <select
                          className="form-control height-35 f-14"
                          id="xName"
                          name="xName"
                          onChange={(e) => setSelectedCompanyId(e.target.value)}
                          value={selectedCompanyId}
                          required
                        >
                          <option value="">Select Payer Name/Company</option>
                          {externalCompanies.list.map((x) => (
                            <option value={x.id}>{x.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )} */}
                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Standing Order
                      </label>
                      {/* <input type="checkbox" /> */}
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            id="standingorder"
                            type="checkbox"
                            className="switch-input"
                            checked={values2.standingOrder}
                            onChange={() => {
                              setValues2({
                                ...values2,
                                standingOrder: !values2.standingOrder,
                              });
                            }}
                          />
                          <span
                            className="switch-label"
                            data-on="YES"
                            data-off="NO"
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values2.standingOrder === true && (
                    <div className="container">
                      <div className="row">
                        {values2.daysOfWeek.map((day, dayIndex) => (
                          <div
                            className="col-md col-md-smlweekcol"
                            key={day.day}
                          >
                            <div className="form-group my-3 mx-2">
                              <div>
                                <label
                                  className="f-14 text-dark"
                                  data-label=""
                                  htmlFor={`toggle-${dayIndex}`}
                                >
                                  {day.dayName}
                                </label>
                                <div className="btn-choice">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      id={`toggle-${dayIndex}`}
                                      checked={day.active}
                                      autoComplete="off"
                                      onChange={() => handleDayToggle(dayIndex)}
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="Yes"
                                      data-off="No"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <label htmlFor="notes2">Note: </label>
                    <textarea
                      type="text"
                      // name="allowed_file_types"
                      id="notes2"
                      placeholder="Enter The Note"
                      className="form-control f-14"
                      autoComplete="false"
                      value={values2.notes}
                      onChange={handleChange("notes")}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="openDropDownButton"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDropDown()}
                >
                  Close
                </button>
                <button
                  type="button"
                  id="submitButton"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
};
export default AddStudentModal;
