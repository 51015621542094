/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReservationV2,
  getReservationsSchedule,
  updateDriverReservationStatus,
  updateReservation,
  updateReservation2,
} from "../../../actions/reservationAction";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import { useLocation } from "react-router-dom";

const AdminDispatchAssignModalComponent = ({
  assigned,
  showDropDown,
  openDropDown,
  current,
  index,
  fullData,
  setFullData,
}) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const [existDriver, setExistDriver] = useState("");
  const [values, setValues] = useState({
    _id: "",
    driverId: "",
    assignedDate: "",
  });
  useEffect(() => {
    setValues({
      ...values,
      _id: current._id,
      driverId: current?.driverId?._id
        ? current?.driverId?._id
        : current?.driverId?.id
        ? current?.driverId?.id
        : "",
    });
    setExistDriver(
      current?.driverId?._id
        ? current?.driverId?._id
        : current?.driverId?.id
        ? current?.driverId?.id
        : ""
    );
  }, [current]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const reservationData = {
    reassigned: true,
    reservationId: values._id,
    driverId: values.driverId,
    assigneeId: auth.user.id,
    assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first==>", current);
    if (!current.driverId) {
      if (reservationData.driverId !== "") {
        const assignDriverData = {
          _id: current?._id,
          driverId: reservationData.driverId,
          assigneeId: auth.user.id,
          assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
          dispatcherId: auth.user.id,
        };
        // dispatch(
        //   updateReservation(assignDriverData, () => {
        //     dispatch(
        //       getAllReservationV2({
        //         companyId: auth.user.companyId.id,
        //         today: moment().format("YYYY-MM-DD"),
        //         isToday: true,
        //       })
        //     );
        //     socketInstance.emit("new-ride-req-send", {
        //       _id: reservationData.reservationId,
        //       driverId: reservationData.driverId,
        //     });
        //     openDropDown();
        //   })
        // );
        dispatch(updateReservation2(assignDriverData, successCB));
        // openDropDown();
      }
      // socketInstance.emit("new-ride-req-send", {
      //   _id: reservationData._id,
      //   driverId: reservationData._id,
      // });
    } else {
      // console.log("sssssssssssssssssssssssss");
      if (reservationData.driverId !== "") {
        dispatch(
          updateDriverReservationStatus(reservationData, async () => {
            openDropDown();
            const { data } = await axios.post(
              `${SERVERADDRESS}/v1/reservation/getReservationById`,
              { _id: reservationData.reservationId }
            );
            setFullData((prevData) => {
              const updatedIndex = prevData.findIndex(
                (item) => item._id === reservationData.reservationId
              ); // Assuming data contains the updated item with an _id field
              // console.log(prevData, "data2");
              if (updatedIndex !== -1) {
                const newData = [...prevData];
                newData[updatedIndex] = {
                  ...data,
                  id: data._id,
                }; // Replace old data with updated data
                return newData;
              }
              return prevData;
            });
            socketInstance.emit("new-ride-req-send", {
              _id: reservationData.reservationId,
              driverId: reservationData.driverId,
            });
            socketInstance?.emit("send-reservation-req", {
              userId: auth.user.id,
              companyId: auth.user.companyId.id,
              reservationId: reservationData.reservationId,
              role: "admin",
              ...(existDriver &&
                existDriver !== reservationData.driverId && {
                  existDriver,
                }),
            });
            // openDropDown();
          })
        );
      } else {
        if (existDriver !== "" && reservationData.driverId === "") {
          const assignDriverData = {
            _id: current?._id,
            removeDriver: true,
            assigneeId: auth.user.id,
            assignedDate:
              moment().format().toString().substring(0, 19) + ".000Z",
            dispatcherId: auth.user.id,
          };

          dispatch(updateReservation2(assignDriverData, successUnassignedCB));
          // openDropDown();
        }
      }
    }

    // openDropDown();
    // setTimeout(() => {
    //   if (assigned === "unassigned") {
    //     dispatch(
    //       getReservationsSchedule({
    //         companyId: auth?.user?.companyId?.id,
    //         today: moment().format("YYYY-MM-DD"),
    //         assigned: assigned,
    //         limit: reservation.unassigned.limit,
    //         page:
    //           reservation.unassigned.page === 1
    //             ? reservation.unassigned.page
    //             : reservation.unassigned.results.length === 1
    //             ? reservation.unassigned.page - 1
    //             : reservation.unassigned.page,
    //       })
    //     );
    //   }
    // }, 2000);
  };

  const successCB = (updatedData) => {
    setFullData((prevData) => {
      const updatedIndex = prevData.findIndex(
        (item) => item._id === updatedData.id
      ); // Assuming data contains the updated item with an _id field
      // console.log(prevData, "data2");
      if (updatedIndex !== -1) {
        const newData = [...prevData];
        newData[updatedIndex] = { ...updatedData, _id: updatedData.id }; // Replace old data with updated data
        return newData;
      }
      return prevData;
    });
    // dispatch(
    //   getAllReservationV2({
    //     companyId: auth.user.companyId.id,
    //     today: moment().format("YYYY-MM-DD"),
    //     isToday: true,
    //   })
    // );
    socketInstance.emit("new-ride-req-send", {
      _id: reservationData.reservationId,
      driverId: reservationData.driverId,
    });
    socketInstance?.emit("send-reservation-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      reservationId: reservationData.reservationId,
      role: "admin",
      ...(existDriver &&
        existDriver !== reservationData.driverId && {
          existDriver,
        }),
    });
    openDropDown();
  };
  const successUnassignedCB = (updatedData) => {
    setFullData((prevData) => {
      const updatedIndex = prevData.findIndex(
        (item) => item._id === updatedData.id
      ); // Assuming data contains the updated item with an _id field
      // console.log(prevData, "data2");
      if (updatedIndex !== -1) {
        const newData = [...prevData];
        newData[updatedIndex] = { ...updatedData, _id: updatedData.id }; // Replace old data with updated data
        return newData;
      }
      return prevData;
    });
    // dispatch(
    //   getAllReservationV2({
    //     companyId: auth.user.companyId.id,
    //     today: moment().format("YYYY-MM-DD"),
    //     isToday: true,
    //   })
    // );
    socketInstance?.emit("send-reservation-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      reservationId: reservationData.reservationId,
      role: "admin",
      existDriver,
    });
    openDropDown();
  };
  // console.log(current,reservationData, "reservationData<")

  const [arr, setArr] = useState([]);
  const locationUrl = useLocation();
  const suggestedNearbyDrivers = async () => {
    if (
      current.type &&
      current.pickUpAddress &&
      current.pickUpTime &&
      current.dropOffTime
    ) {
      // const abc = JSON.parse(values.type);
      if (locationUrl.pathname !== "/past-bookings") {
        const { data } = await axios.post(
          `${SERVERADDRESS}/v2/reservation/suggestedNearbyDrivers`,
          {
            companyId: auth?.user?.companyId?.id,
            // driverId: auth?.user?.id,
            serviceType: current.type._id ? current.type._id : current.type.id,
            lng: current.pickUpAddress.longitude,
            lat: current.pickUpAddress.latitude,
            pickUpTime: current.pickUpTime,
            dropOffTime: current.dropOffTime,
          }
        );
        setArr(data);
      }
    }
  };
  // console.log(values, "values ======>");
  // console.log(arr, "arr  >");
  // console.log(current, "currrrrr");
  useEffect(() => {
    suggestedNearbyDrivers();
  }, [reservation, values]);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const handleCheckboxChange3 = (driverId) => {
    setSelectedDriver(driverId);
    setValues({
      ...values,
      driverId: driverId,
    });
  };
  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="selectTheDriver">
                {" "}
                Select The Driver
              </h5>
              <button
                type="button"
                className="close"
                id="button1"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-3">
                    <div className="row">
                      <div className="col-md-12 pl-4">
                        {arr.length > 0 ? (
                          <div className="row my-3">
                            <div className="col-md-12">
                              <div
                                className="text-dark-grey mb-2"
                                data-label=""
                              >
                                <strong>Suggested Drivers:</strong>
                              </div>
                            </div>
                            {arr.length > 0 &&
                              arr.map((c, i) => {
                                const routeTripD = c?.distanceMiles
                                  ? (c?.distanceMiles?.toFixed(2) / 25)
                                      .toString()
                                      .split(".")
                                  : 0.0;
                                const routeTripDHrs = routeTripD[0];
                                let routeTripDMin;
                                let routeTripDMinStr = "0." + routeTripD[1];
                                routeTripDMinStr = parseFloat(routeTripDMinStr);
                                routeTripDMin = routeTripDMinStr;
                                const routeTripD2 = routeTripDMin * 60;
                                const routeTripDMin2 = routeTripD2
                                  .toString()
                                  .split(".");
                                let displayTime =
                                  routeTripDHrs +
                                  "h " +
                                  routeTripDMin2[0] +
                                  "m";
                                return (
                                  <div
                                    className={`col-md-${12 / arr.length}`}
                                    key={i}
                                  >
                                    <div className="text-dark-grey">
                                      <div className="d-flex">
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedDriver === c.driverId
                                          }
                                          onChange={() =>
                                            handleCheckboxChange3(c.driverId)
                                          }
                                        />{" "}
                                        &nbsp;
                                        <strong>
                                          {c.user.firstName} {c.user.lastName}
                                        </strong>
                                      </div>
                                      (<strong>D:</strong>{" "}
                                      {c.distanceMiles.toFixed(2)} mi,{" "}
                                      <strong>ETA:</strong> {displayTime}
                                      {c.reservation && (
                                        <>
                                          , <strong>Dropoff:</strong>{" "}
                                          {c.reservation.dropOffTime}
                                        </>
                                      )}
                                      )
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="form-group">
                          <select
                            name="default_task_status"
                            id="default_task_status"
                            className="form-control"
                            style={{ height: "39px" }}
                            value={values.driverId}
                            onChange={handleChange("driverId")}
                          >
                            <option value="">Select the Driver</option>
                            {users?.allDriversWithoutPagination?.length > 0 &&
                              users?.allDriversWithoutPagination?.map(
                                (current, i) => {
                                  return (
                                    <option value={current.id} key={i}>
                                      {current.firstName +
                                        " " +
                                        current.lastName}
                                    </option>
                                  );
                                }
                              )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                id="button2"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                disabled={loading}
                onClick={() => openDropDown()}
              >
                Close
              </button>
              {/* <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>Save changes</button> */}
              <button
                type="button"
                id="button3"
                className="btn-primary rounded f-14 p-2 mr-3"
                disabled={loading}
                onClick={handleSubmit}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDispatchAssignModalComponent;
