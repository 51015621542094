import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUserLoginInforByCompanyId } from "../../../actions/authActions";
import Accordion from "react-bootstrap/Accordion";
import AdminUserLogInActivitiesRowComponent from "./AdminUserLogInActivitiesRowComponent";
import defaultimg from "../../../assets/images/profile.jpg";
import AdminDriverLogInActivitiesRowComponent from "./AdminDriverLogInActivitiesRowComponent";
import moment from "moment";

const AdminDriverLogInActivitiesComponent = () => {
  const [selectedImage, setSelectedImage] = useState(defaultimg);
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });
  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  const userActivityData = async (e) => {
    dispatch(
      getAllUserLoginInforByCompanyId({
        companyId: auth?.user?.companyId?.id,
      })
    );
  };
  useEffect(() => {
    userActivityData();
  }, []);

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
          <div className="row">
            <div className="col-sm-12">
              <Accordion defaultActiveKey="0">
                {auth &&
                  auth.allUserLoginInActivity &&
                  auth.allUserLoginInActivity.length > 0 &&
                  auth.allUserLoginInActivity
                    .filter((user) => user.user.isDriver === true)
                    .map((user, index) => {
                      // user.user.isDriver === true;
                      const loginInfoByDate = {}; // Object to store login info by date
                      user.loggedInInfo.forEach((login) => {
                        const loginDate = new Date(login.loginTime); // Convert login time to Date object
                        // console.log(loginDate, "<-- loginDate");
                        const date = `${
                          loginDate.getMonth() + 1
                        }/${loginDate.getDate()}/${loginDate.getFullYear()}`; // Format date
                        // console.log(date, "<-- date before if logic");

                        if (!loginInfoByDate[date]) {
                          loginInfoByDate[date] = {
                            totalDuration: 0,
                            logins: [],
                          };
                        }
                        if (login.loginDuration) {
                          loginInfoByDate[date].totalDuration +=
                            login.loginDuration;
                        } else {
                          loginInfoByDate[date].totalDuration = calculateTime(
                            login.loginTime
                          );
                        }
                        loginInfoByDate[date].logins.push(login);

                        // console.log(loginInfoByDate, "<-- loginInfoByDate");
                        // console.log(date, "<-- date after if logic");
                      });
                      function calculateTime(date) {
                        const loginDuration =
                          (new Date() - new Date(date)) / (1000 * 60 * 60);
                        const loginD = loginDuration.toString().split(".");
                        const loginDHrs = loginD[0];
                        let loginDMin;
                        let loginDMinStr = "0." + loginD[1];
                        loginDMinStr = parseFloat(loginDMinStr);
                        loginDMin = loginDMinStr;
                        const loginD2 = loginDMin * 60;
                        const loginDMin2 = loginD2.toString().split(".");
                        return loginDHrs + "h " + loginDMin2[0] + "m";
                      }
                      function parseTotalDuration(totalDuration) {
                        // Regular expression to match hours and minutes
                        const regex = /(\d+)h\s*(\d+)m/g;
                        let totalHours = 0;
                        let totalMinutes = 0;

                        let match;
                        while ((match = regex.exec(totalDuration)) !== null) {
                          const hours = parseInt(match[1]);
                          const minutes = parseInt(match[2]);
                          totalHours += hours;
                          totalMinutes += minutes;
                        }

                        // Adjust minutes if they exceed 60
                        totalHours += Math.floor(totalMinutes / 60);
                        totalMinutes %= 60;

                        return `${totalHours}h ${totalMinutes}m`;
                      }
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header className="w-100">
                            {user.user &&
                            user.user.profilePic &&
                            user.user.profilePic.fileUrl ? (
                              <img
                                className="inline-block"
                                src={user.user.profilePic.fileUrl}
                                alt="Profile Pic"
                                style={{
                                  borderRadius: "50%",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            ) : (
                              <img
                                className="inline-block"
                                src={selectedImage}
                                alt="Profile Pic"
                                style={{
                                  borderRadius: "50%",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            )}
                            {` ${user?.user?.firstName} ${user?.user?.lastName}`}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion>
                              {Object.entries(loginInfoByDate).map(
                                ([date, info], i) => (
                                  <Accordion.Item eventKey={i} key={i}>
                                    <Accordion.Header>
                                      <td className="border-right">
                                        {moment(date).format("MM/DD/YYYY")}
                                      </td>
                                      <td className="">
                                        {"\u00A0"}Total Duration:{" "}
                                        {parseTotalDuration(info.totalDuration)}
                                      </td>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <table
                                        className="table border w-100 dataTable no-footer"
                                        id="leads-table"
                                        role="grid"
                                        aria-describedby="leads-table_info"
                                        style={{
                                          overflowX: "auto",
                                          width: "1065px",
                                        }}
                                      >
                                        <thead className="card-header">
                                          <tr role="row">
                                            <th
                                              title="Country"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              Country
                                            </th>
                                            <th
                                              title="State"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              State
                                            </th>
                                            <th
                                              title="IP Address"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              IP Address
                                            </th>
                                            <th
                                              title="Device Type"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              Device Type
                                            </th>
                                            <th
                                              title="Log In Activities"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              Log In Activities
                                            </th>
                                            <th
                                              title="Log Out Activities"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              Log Out Activities
                                            </th>
                                            <th
                                              title="Duration"
                                              className="sorting_disabled border-right"
                                              rowSpan="1"
                                              colSpan="1"
                                              aria-label="Next Follow Up"
                                            >
                                              Duration
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* {user?.loggedInInfo?.map((current, i) => (
                                                                <AdminDriverLogInActivitiesRowComponent
                                                                    current={current}
                                                                    i={i}
                                                                    setModal={setModal}
                                                                    key={i} />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Accordion.Body>
                                            </Accordion.Item> */}
                                          {info.logins.map((current, i) => (
                                            // console.log(current, i, "current, i"),
                                            <AdminUserLogInActivitiesRowComponent
                                              current={current}
                                              i={i}
                                              setModal={setModal}
                                              key={i}
                                            />
                                          ))}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                )
                              )}
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                {/* );
                                    })} */}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDriverLogInActivitiesComponent;
