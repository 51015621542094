/** @format */

// import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allFacilities,
  // getAllCardsData,
  // getAllCardsData2,
  getAllCardsData3,
  mostTripsByDriverInAWeek,
} from "../../../actions/kpiActions";
import {
  allCompanyUsers,
  allDriversAvailableForRoutes,
  allEmployees,
  getAllClients,
  // getAllActiveUser,
  // getAllDriver,
  getAllDriverWithoutPagination,
  // getAllInactiveUser,
  getAllStudents,
  // getAllUsers,
  getRoutePassengersByCompanyId,
  getTracksByCompanyId,
  onlineUsers,
} from "../../../actions/userActions";
import {
  allBadges,
  getAllVehiclesByCompanyId,
} from "../../../actions/vehicleActions";
// import { getMailByUserId } from "../../../actions/mailActions";
import { getAllPermission } from "../../../actions/permissionActions";
import {
  // getAllRoutesOfCompany,
  getAllRoutesOfCompanyV2,
} from "../../../actions/routeActions";
import {
  // getAllReservationV2,
  getFutureReservationsByCompanyId,
  getOnlineReservationsByCompanyId,
  // getPastReservationsByCompanyId,
  getPastReservationsByCompanyIdV3,
  getQuoteReservationsByCompanyId,
  getTodayReservationsByCompanyId,
  // getReservationsScheduleV2,
} from "../../../actions/reservationAction";
// import {
//   getAllInvoicesActionV2,
//   invoiceSearch,
// } from "../../../actions/invoiceActions";
import { Link, NavLink } from "react-router-dom";

const AdminDashboardBookedOrderSummaryCard = () => {
  const kpi = useSelector((state) => state.kpi);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();
  // const cardsData = async (e) => {
  //     dispatch(getAllCardsData({companyId: auth.user.companyId.id }))
  // }
  const cardsData2 = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      getAllCardsData3({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      mostTripsByDriverInAWeek({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(getAllPermission({ companyId: auth.user.companyId.id }));
    dispatch(allFacilities({ companyId: auth.user.companyId.id }));
  };
  useEffect(() => {
    cardsData2();
  }, []);
  // console.log(kpi, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")

  // externalCompanyId ayega companyId ki jaga ? aur passengerwithoutpagination bh bula ln
  const passengerData = async (e) => {
    dispatch(getAllStudents({ companyId: auth.user.companyId.id }));
    dispatch(getAllClients({ companyId: auth.user.companyId.id }));
    dispatch(
      getRoutePassengersByCompanyId({ companyId: auth.user.companyId.id })
    );
  };

  // const userData = async (e) => {
  //   dispatch(
  //     getAllDriver({
  //       companyId: auth.user.companyId.id,
  //       isClient: false,
  //       jobTitle: "Driver",
  //     })
  //   );
  // };
  const driverData = async (e) => {
    dispatch(
      getAllDriverWithoutPagination({ companyId: auth.user.companyId.id })
    );
  };
  const routeDriverData = async (e) => {
    dispatch(
      allDriversAvailableForRoutes({ companyId: auth.user.companyId.id })
    );
  };
  const allEmployeesData = async (e) => {
    const data = {
      companyId: auth.user.companyId.id,
      userId: auth.user.id,
    };
    if (users?.onlineUsers?.length) {
      data.exist = users?.onlineUsers;
    }
    dispatch(onlineUsers(data));
    dispatch(
      allEmployees({
        companyId: auth.user.companyId.id,
        userId: auth.user.id,
      })
    );
  };
  const allVehicleData = async (e) => {
    dispatch(getAllVehiclesByCompanyId({ companyId: auth.user.companyId.id }));
  };
  // const clientData = async (e) => {
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?.id, isClient: true },
  //       10,
  //       1
  //     )
  //   );
  // };
  // const allActiveUserData = async (e) => {
  //   dispatch(
  //     getAllActiveUser(
  //       {
  //         companyId: auth?.user?.companyId?.id,
  //         isClient: true,
  //         disable: false,
  //       },
  //       10,
  //       1
  //     )
  //   );
  // };
  // const allInactiveUserData = async (e) => {
  //   dispatch(
  //     getAllInactiveUser(
  //       { companyId: auth?.user?.companyId?.id, isClient: true, disable: true },
  //       10,
  //       1
  //     )
  //   );
  // };
  // const mailData = async () => {
  //   dispatch(getMailByUserId({ userId: auth.user.id }));
  // };
  // const getAllRoutesOfCompanyData = async (e) => {
  //   dispatch(
  //     getAllRoutesOfCompany({ companyId: auth?.user?.companyId?.id }, 10, 1)
  //   );
  // };

  const routeData = async (e) => {
    dispatch(
      getAllRoutesOfCompanyV2({
        companyId: auth.user.companyId.id,
      })
    );
  };

  const reservationData = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    // dispatch(
    //   getAllReservationV2({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    dispatch(
      getTodayReservationsByCompanyId({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      getOnlineReservationsByCompanyId({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      getQuoteReservationsByCompanyId({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      getFutureReservationsByCompanyId({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    // dispatch(
    //   getPastReservationsByCompanyId({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    dispatch(
      getPastReservationsByCompanyIdV3({
        limit: 50,
        page: 1,
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
  };

  // const reservationData = async (e) => {
  //   dispatch(
  //     getAllReservationV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       // isToday: true,
  //     })
  //   );
  // };

  // const reservationsScheduleData = async (e) => {
  //   dispatch(
  //     getReservationsScheduleV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       // assigned: "pending",
  //     })
  //   );
  // };
  // const getInvoices = async () => {
  //   dispatch(
  //     getAllInvoicesActionV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       weekFirst:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       weekLast:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       monthFirst: moment().startOf("month").format("YYYY-MM-DD"),
  //       monthLast: moment().endOf("month").format("YYYY-MM-DD"),
  //     })
  //   );
  //   dispatch(
  //     invoiceSearch({
  //       companyId: auth.user.companyId.id,
  //       from:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //       to:
  //         moment().format("dddd") !== "Sunday"
  //           ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
  //           : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
  //     })
  //   );
  // };
  const userDataa = async (e) => {
    dispatch(
      allCompanyUsers({
        companyId: auth?.user?.companyId?.id,
        // isClient: false
      })
    );
  };
  const trackDriverData = async (e) => {
    dispatch(
      getTracksByCompanyId({
        companyId: auth?.user?.companyId?.id,
      })
    );
  };
  const allBadgesData = async () => {
    dispatch(allBadges({ companyId: auth.user.companyId.id }));
  };
  // useEffect(() => {
  //   userData();
  // }, []);
  useEffect(() => {
    // userData();
    reservationData();
    driverData();
    routeDriverData();
    allVehicleData();
    // clientData();
    allEmployeesData();
    // mailData();
    // allActiveUserData();
    // allInactiveUserData();
    // getAllRoutesOfCompanyData();
    passengerData();
    // reservationsScheduleData();
    routeData();
    // getInvoices();
    userDataa();
    trackDriverData();
    allBadgesData();
  }, []);

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
        <div className="row">
          <div className="col-md-3">
            <div className="b-shadow-4 rounded p-2 bg-col-new">
              <div
                className="card-body p-2 clearfix"
                style={{ minHeight: "240px" }}
              >
                <div className="driver-text mb-3">
                  <NavLink
                    to="/today-bookings"
                    className="card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs"
                  >
                    TODAY'S TRIPS
                  </NavLink>
                </div>
                <NavLink
                  to="/today-bookings?active=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">Active Trips</p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderActiveCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/today-bookings?completed=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      Completed Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderCompletedCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/today-bookings?noshow=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      No Show Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderNoShowCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/today-bookings?cancel=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">Cancel Trips</p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderCancelCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/today-bookings?notstarted=true&requested=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      Not Started Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderNotStartedCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/today-bookings"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">Total Trips</p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTodayOrderCount}
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="b-shadow-4 rounded p-2 bg-col-new">
              <div
                className="card-body p-2 clearfix"
                style={{ minHeight: "250px" }}
              >
                <div className="driver-text mb-3">
                  <NavLink
                    to="/scheduled-bookings"
                    className="card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs"
                  >
                    UPCOMING TRIPS
                  </NavLink>
                </div>
                <NavLink
                  to="/scheduled-bookings?scheduled=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      Assigned Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowOrderAssignedCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/scheduled-bookings?unassigned=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      Unassigned Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowOrderUnassignedCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/scheduled-bookings?not-accepted=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      Drivers Not Yet Accepted Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowRequestedCount}
                    </p>
                  </div>
                </NavLink>
                {/* <NavLink
                  to="/scheduled-bookings?cancel=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">Cancel Trips</p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowOrderCancelCount}
                    </p>
                  </div>
                </NavLink> */}
                {/* <NavLink
                  to="/scheduled-bookings?noshow=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      No Show Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowOrderNoShowCount}
                    </p>
                  </div>
                </NavLink> */}
                <NavLink
                  to="/scheduled-bookings"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">Total Trips</p>
                  </div>
                  <div className="driver-text">
                    <p className="text-black f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.totalTomorrowOrderCount}
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="b-shadow-4 rounded p-2 bg-col-new">
              <div
                className="card-body p-2 clearfix"
                style={{ minHeight: "250px" }}
              >
                <div className="driver-text mb-3">
                  <NavLink
                    to="/past-bookings"
                    className="card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs"
                  >
                    PAST TRIPS
                  </NavLink>
                </div>
                <NavLink
                  to="/past-bookings?this-week=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastThisWeekOemcCount}, rtn: ${kpi?.cardsData2?.pastThisWeekRtnCount}, shuttle van: ${kpi?.cardsData2?.pastThisWeekShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastThisWeekNemtCount}`}
                    >
                      This Week Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastThisWeekOemcCount}, rtn: ${kpi?.cardsData2?.pastThisWeekRtnCount}, shuttle van: ${kpi?.cardsData2?.pastThisWeekShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastThisWeekNemtCount}`}
                    >
                      {kpi?.cardsData2?.pastThisWeekOrderCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/past-bookings?last-week=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p
                      className="text-black f-16 f-w-400 mb-0"
                      title={`oemc: ${kpi?.cardsData2?.pastLastWeekOemcCount}, rtn: ${kpi?.cardsData2?.pastLastWeekRtnCount}, shuttle van: ${kpi?.cardsData2?.pastLastWeekShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastLastWeekNemtCount}`}
                    >
                      Last Week Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p
                      className="text-black f-16 f-w-400 mb-0"
                      title={`oemc: ${kpi?.cardsData2?.pastLastWeekOemcCount}, rtn: ${kpi?.cardsData2?.pastLastWeekRtnCount}, shuttle van: ${kpi?.cardsData2?.pastLastWeekShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastLastWeekNemtCount}`}
                    >
                      {kpi?.cardsData2?.pastLastWeekOrderCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/past-bookings?this-month=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastThisMonthOemcCount}, rtn: ${kpi?.cardsData2?.pastThisMonthRtnCount}, shuttle van: ${kpi?.cardsData2?.pastThisMonthShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastThisMonthNemtCount}`}
                    >
                      This Month Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastThisMonthOemcCount}, rtn: ${kpi?.cardsData2?.pastThisMonthRtnCount}, shuttle van: ${kpi?.cardsData2?.pastThisMonthShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastThisMonthNemtCount}`}
                    >
                      {kpi?.cardsData2?.pastThisMonthOrderCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/past-bookings?last-month=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastLastMonthOemcCount}, rtn: ${kpi?.cardsData2?.pastLastMonthRtnCount}, shuttle van: ${kpi?.cardsData2?.pastLastMonthShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastLastMonthNemtCount}`}
                    >
                      Last Month Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastLastMonthOemcCount}, rtn: ${kpi?.cardsData2?.pastLastMonthRtnCount}, shuttle van: ${kpi?.cardsData2?.pastLastMonthShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastLastMonthNemtCount}`}
                    >
                      {kpi?.cardsData2?.pastLastMonthOrderCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/past-bookings"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastTotalOemcCount}, rtn: ${kpi?.cardsData2?.pastTotalRtnCount}, shuttle van: ${kpi?.cardsData2?.pastTotalShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastTotalNemtCount}`}
                    >
                      Total Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p
                      className="f-16 f-w-400 mb-0 text-black"
                      title={`oemc: ${kpi?.cardsData2?.pastTotalOemcCount}, rtn: ${kpi?.cardsData2?.pastTotalRtnCount}, shuttle van: ${kpi?.cardsData2?.pastTotalShuttleVanCount}, nemt: ${kpi?.cardsData2?.pastTotalNemtCount}`}
                    >
                      {kpi?.cardsData2?.pastTotalOrderCount}
                    </p>
                  </div>
                </NavLink>
                {/* <NavLink
                  to="/past-bookings?completed=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      Completed Trips
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      {kpi?.cardsData2?.pastTodayOrderCompletedCount}
                    </p>
                  </div>
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="b-shadow-4 rounded p-2 bg-col-new">
              <div
                className="card-body p-2 clearfix"
                style={{ minHeight: "250px" }}
              >
                <div className="driver-text mb-3">
                  <div className="card-headertext-blue h5 f-21 font-weight-bold font-weight-bold font-xs">
                    DRIVER
                  </div>
                </div>

                <NavLink
                  to="/users?online-drivers=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      Online Drivers
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      {kpi?.cardsData2?.onlineDriverCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/users?offline-drivers=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      Offline Drivers
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      {kpi?.cardsData2?.offlineDriverCount}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/users?all-drivers=true"
                  className="d-flex justify-content-between mb-2"
                >
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      Total Drivers
                    </p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0 text-black">
                      {kpi?.cardsData2?.driversCount}
                    </p>
                  </div>
                </NavLink>
                {/* <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Total Drivers</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.driversCount}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Online Drivers</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.onlineDriverCount}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">Offline Drivers</p>
                  </div>
                  <div className="driver-text">
                    <p className="f-16 f-w-400 mb-0">
                      {kpi?.cardsData2?.offlineDriverCount}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboardBookedOrderSummaryCard;
