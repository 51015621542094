/** @format */

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import AdminBookingTodayBookingRideLogModal from "./AdminBookingTodayBookingRideLogModal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminCancelBooking from "../adminDispatchingComponents/AdminCancelBooking";
import AdminDispatchAssignModalComponent from "../adminDispatchingComponents/AdminDispatchAssignModalComponent";
import AdminBookingPriceDetailModal from "./AdminBookingPriceDetailModal";
import { notifyFailure } from "../../toast";
import {
  getReservationById,
  sendQuotation,
  updateReservation,
  updateReservation2,
} from "../../../actions/reservationAction";
import AdminCompleteRideDeletion from "../adminDispatchingComponents/AdminCompleteRideDeletion";
import AdminBookingAuditTrialActivityModal from "./AdminBookingAuditTrialActivityModal";
import AdminAddDispatchNoteModal from "./AdminAddDispatchNoteModal";
import {
  createGroupAction,
  getMessagesAction,
  resetState,
} from "../../../actions/chatActions";
import AdminBookingGroupMessageRow from "./AdminBookingGroupMessageRow";
import AdminUndoCancelRide from "../adminDispatchingComponents/AdminUndoCancelRide";
import AdminSendQuoteModal from "./AdminSendQuoteModal";

const AdminBookingFutureBookingRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  fullData,
  setFullData,
}) => {
  const directionsService = new window.google.maps.DirectionsService();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [showAuditTrialDropDown, setshowAuditTrialDropDown] = useState(false);
  const openAuditTrialDropDown = () => {
    setshowAuditTrialDropDown(
      (showAuditTrialDropDown) => !showAuditTrialDropDown
    );
  };

  const [showRideLogDropDown, setshowRideLogDropDown] = useState(false);
  const openRideLogDropDown = () => {
    setshowRideLogDropDown((showRideLogDropDown) => !showRideLogDropDown);
  };
  const [addDispatchNote, setAddDispatchNote] = useState(false);
  const openAddDispatchNote = () => {
    setAddDispatchNote((addDispatchNote) => !addDispatchNote);
  };
  const [sendQuote, setSendQuote] = useState(false);
  const openSendQuote = () => {
    setSendQuote((sendQuote) => !sendQuote);
  };
  const auth = useSelector((state) => state.auth);
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const navigate = useNavigate();
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = () => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
    setshowMoreVertDropDown(false);
  };
  const [showUndoDropDown, setshowUndoDropDown] = useState(false);
  const openUndoDropDown = () => {
    setshowUndoDropDown((showDeleteDropDown) => !showUndoDropDown);
    setshowMoreVertDropDown(false);
  };
  const vehicle = useSelector((state) => state.vehicle);
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const [showAssignDriverDropDown, setShowAssignDriverDropDown] =
    useState(false);

  const openAssignDriverDropDown = () => {
    setShowAssignDriverDropDown((prev) => !prev);
  };

  const [fareDetails, setFareDetails] = useState();
  const [values, setValues] = useState();
  let [amount, setAmount] = useState(0);
  // yahan se new logic
  const handleVeryDirectFare = () => {
    // setHardCodedFares({
    //   ...hardCodedFares,
    //   // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
    //   [fieldName]: event.target.value,
    // });
    const dh =
      parseFloat(parseFloat(hardCodedFares.distanceFromBaseLocation)) >
      parseFloat(hardCodedFares?.deadHeadThreshold)
        ? parseFloat(
            parseFloat(
              parseFloat(hardCodedFares.distanceFromBaseLocation) *
                parseFloat(hardCodedFares?.baseFarePerMile)
            ).toFixed(2)
          )
        : 0;
    const temp = parseFloat(
      (
        parseFloat(
          parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2)
          ) * parseFloat(hardCodedFares.farePerMile)
        ) +
        // parseFloat(hardCodedFares.offHourFee ? hardCodedFares.offHourFee : 0) +
        parseFloat(hardCodedFares.flatBaseFare) +
        (current.connectedReservation ? dh / 2 : dh)
      ).toFixed(2)
    );

    const tempD = !current.connectedReservation
      ? parseFloat(
          ((temp - dh / 2) * (hardCodedFares.driverPercentage / 100)).toFixed(2)
        )
      : parseFloat((temp * (hardCodedFares.driverPercentage / 100)).toFixed(2));
    // console.log(
    //   parseFloat(hardCodedFares?.deadHeadThreshold),
    //   parseFloat(hardCodedFares.distanceFromBaseLocation),
    //   dh,
    //   "temporary"
    // );
    setFareDetails({
      ...fareDetails,
      distanceFromBaseLocation: parseFloat(
        parseFloat(hardCodedFares?.distanceFromBaseLocation)?.toFixed(2)
      ),
      distanceFromPickToDropOff: parseFloat(
        parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
      ),
      precalculatedMiles: parseFloat(
        parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
      ),
      apiDistanceFromPickToDropOff: hardCodedFares.apiDistanceFromPickToDropOff,
      usePrecalculatedMiles: hardCodedFares.usePrecalculatedMiles,
      totalMileage: parseFloat(
        (
          parseFloat(hardCodedFares.distanceFromBaseLocation) +
          parseFloat(hardCodedFares.distanceFromPickToDropOff)
        ).toFixed(2)
      ),
      baseFare: parseFloat(parseFloat(hardCodedFares.flatBaseFare).toFixed(2)),
      baseFareStructure: {
        values: {
          flatBaseFare: hardCodedFares.flatBaseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadheadFare: current?.connectedReservation ? dh / 2 : dh,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: hardCodedFares.baseFarePerMile,
          distanceFromBaseLocation: distanceFromBaseLocation,
        },
        valuesFormula: current?.connectedReservation
          ? "(baseFarePerMile * distanceFromBaseLocation)/2"
          : "baseFarePerMile * distanceFromBaseLocation",
      },
      rideFare: parseFloat(
        (
          parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
          parseFloat(hardCodedFares.farePerMile)
        ).toFixed(2)
      ),
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff: hardCodedFares.distanceFromPickToDropOff,
          farePerMile: hardCodedFares.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: parseFloat(hardCodedFares.offHourFee),
      amount: temp,
      amountStructure: {
        values: {
          rideFare: parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
              parseFloat(hardCodedFares.farePerMile)
          ).toFixed(2),
          // offHourFee: parseFloat(hardCodedFares.offHourFee),
          baseFare: parseFloat(hardCodedFares.flatBaseFare).toFixed(2),
          deadheadFare: current.connectedReservation ? dh / 2 : dh,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: hardCodedFares.driverPercentage,
      driverAmount: parseFloat(tempD.toFixed(2)),
      companyAmount: parseFloat((temp - tempD).toFixed(2)),
    });
    setAmount(temp);
    setMil(hardCodedFares.distanceFromPickToDropOff);
  };
  // yahan se new logic
  const [stopArrUseApi, setStopArrUseApi] = useState(false);
  const [stopArr, setStopArr] = useState([
    // {
    //   address: "",
    //   date: "",
    //   time: "",
    // },
    // {
    //   address: "",
    //   date: "",
    //   time: "",
    // },
  ]);
  const location = useSelector((state) => state.location);
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let totalMileage = 0;
  const fare = useSelector((state) => state.fare);
  const [eta, setEta] = useState();
  const [mil, setMil] = useState(0);
  const fareFunc = () => {
    totalMileage = parseFloat(values?.totalMileage?.toFixed(2));
    const abc = values?.type;
    let obj = {};
    let fares = fare.fare;
    let baseFare;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;
    let abcId = abc?._id ? abc?._id : abc?.id;
    const company = auth?.user?.companyId;
    if (
      details.isCompany === false ||
      details.isCompany === "" ||
      !details.isCompany
    ) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abcId) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(
        //   "yahan issue hai",
        //   e,
        //   e.business.id === details.externalCompanyId,
        //   e.business.id,
        //   details.externalCompanyId
        // );
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        // console.log(
        //   "yahan=>",
        //   e.serviceId,
        //   e.serviceId.id,
        //   abc,
        //   e.serviceId.id === abcId
        // );
        if (e.serviceId.id === abcId) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    // console.log("serviceFare=>", serviceFare, fares, companyFare);
    let _fare;
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        // console.log(_fare, totalMileage, "_fare");
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    // console.log("_fare=>", _fare);
    // yahan se new logic
    // if (useHardCodedFares === true) {
    //   _fare = {
    //     flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
    //     farePerMile: parseFloat(hardCodedFares.farePerMile),
    //     deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
    //     baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
    //     totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
    //     longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
    //     offHourFee: parseFloat(hardCodedFares.offHourFee),
    //     noShowFee: parseFloat(hardCodedFares.noShowFee),
    //     cancelationFee: parseFloat(hardCodedFares.cancelationFee),
    //   };
    // }
    // yahan takh new logic
    // obj.fare = _fare;
    // if (distanceFromBaseLocation <= _fare?.deadHeadThreshold) {
    //   baseFare = _fare?.flatBaseFare;
    //   obj.baseFare = baseFare;
    //   obj.baseFareStructure = {
    //     values: { flatBaseFare: _fare?.flatBaseFare },
    //     valuesFormula: "flatBaseFare",
    //   };
    // } else {
    //   // yahan se new logic
    //   if (_fare?.deadHeadThreshold) {
    //     baseFare =
    //       _fare?.flatBaseFare +
    //       _fare?.baseFarePerMile * distanceFromBaseLocation;
    //     obj.baseFare = baseFare;
    //     obj.baseFareStructure = {
    //       values: {
    //         flatBaseFare: _fare?.flatBaseFare,
    //         baseFarePerMile: _fare?.baseFarePerMile,
    //         distanceFromBaseLocation: distanceFromBaseLocation,
    //       },
    //       valuesFormula:
    //         "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
    //     };
    //   } else {
    //     baseFare = _fare?.flatBaseFare;
    //     obj.baseFare = baseFare;
    //     obj.baseFareStructure = {
    //       values: { flatBaseFare: _fare?.flatBaseFare },
    //       valuesFormula: "flatBaseFare",
    //     };
    //   }
    //   // yahan takh new logic
    // }
    // if (distanceFromPickToDropOff <= _fare?.totalMileageAllows) {
    //   // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
    //   rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
    //   obj.rideFare = rideFare;
    //   obj.rideFareStructure = {
    //     values: {
    //       distanceFromPickToDropOff: distanceFromPickToDropOff,
    //       farePerMile: _fare?.farePerMile,
    //     },
    //     valuesFormula: "distanceFromPickToDropOff * farePerMile",
    //   };
    // } else {
    //   // yahan se new logic
    //   if (_fare?.totalMileageAllows) {
    //     rideFare = distanceFromPickToDropOff * _fare?.longDistancePerMile;
    //     obj.rideFare = rideFare;
    //     obj.rideFareStructure = {
    //       values: {
    //         distanceFromPickToDropOff: distanceFromPickToDropOff,
    //         longDistancePerMile: _fare?.longDistancePerMile,
    //       },
    //       valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
    //     };
    //   } else {
    //     rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
    //     obj.rideFare = rideFare;
    //     obj.rideFareStructure = {
    //       values: {
    //         distanceFromPickToDropOff: distanceFromPickToDropOff,
    //         farePerMile: _fare?.farePerMile,
    //       },
    //       valuesFormula: "distanceFromPickToDropOff * farePerMile",
    //     };
    //   }
    //   // yahan takh
    // }
    // for (let key of Object.keys(company.availability)) {
    //   // console.log(key, key === (moment(stopArr[0]?.date).format("dddd")  !== 'Sunday' ? moment(stopArr[0]?.date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0]?.date).endOf('week').add(-6, 'days').format('dddd')), "checkss")
    //   // logic added if else, first one second one added
    //   // if (key === (moment(stopArr[0]?.date).format("dddd")  !== 'Sunday' ? moment(stopArr[0]?.date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0]?.date).endOf('week').add(-6, 'days').format('dddd'))) {
    //   // }
    //   if (moment(stopArr[0]?.date).format("dddd") !== "Sunday") {
    //     if (key === moment(stopArr[0]?.date).format("dddd")) {
    //       if (company.availability[key] === false) {
    //         offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
    //         obj.offHourFee = offHourFee;
    //       }
    //     }
    //   } else {
    //     if (
    //       key ===
    //       moment(stopArr[0]?.date).endOf("week").add(-6, "days").format("dddd")
    //     ) {
    //       if (company.availability[key] === false) {
    //         offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
    //         obj.offHourFee = offHourFee;
    //       }
    //     }
    //   }
    // }
    // if (offHourFee === 0) {
    //   if (
    //     parseInt(company?.offHours?.from?.substring(0, 2)) <=
    //       parseInt(stopArr[0]?.time.substring(0, 2)) &&
    //     parseInt(stopArr[0]?.time.substring(0, 2)) <=
    //       parseInt(company?.offHours?.to?.substring(0, 2))
    //   ) {
    //     if (
    //       parseInt(company?.offHours?.from?.substring(3, 5)) <=
    //       parseInt(stopArr[0]?.time.substring(3, 5))
    //     ) {
    //       offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
    //       obj.offHourFee = offHourFee;
    //     }
    //   }
    //   // else {
    //   //   console.log("normal")
    //   // }
    // }

    // // old but working code below
    // setHardCodedFares({
    //   ...hardCodedFares,
    //   flatBaseFare: _fare?.flatBaseFare,
    //   farePerMile: _fare?.farePerMile,
    //   deadHeadThreshold: _fare?.deadHeadThreshold,
    //   baseFarePerMile: _fare?.baseFarePerMile,
    //   totalMileageAllows: _fare?.totalMileageAllows,
    //   longDistancePerMile: _fare?.longDistancePerMile,
    //   offHourFee: _fare?.offHourFee,
    //   noShowFee: _fare?.noShowFee,
    //   cancelationFee: _fare?.cancelationFee,
    //   usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles
    //     ? hardCodedFares?.usePrecalculatedMiles
    //     : false,
    //   precalculatedMiles: current?.fareAndDistance?.precalculatedMiles
    //     ? hardCodedFares?.precalculatedMiles
    //     : 0,
    //   // additionalCharges: 0,
    //   // deduction: 0,
    // });
    setHardCodedFares({
      ...hardCodedFares,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      precalculatedMiles: current?.fareAndDistance?.precalculatedMiles,
      apiDistanceFromPickToDropOff:
        current?.fareAndDistance?.apiDistanceFromPickToDropOff,
      usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles,
      totalMileage: current?.fareAndDistance?.totalMileage,
      baseFare: current?.fareAndDistance?.baseFare,
      flatBaseFare: current?.fareAndDistance?.baseFare,
      baseFareStructure: {
        values: {
          flatBaseFare: current?.fareAndDistance?.baseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadHeadThreshold: current?.fareAndDistance?.fare?.deadHeadThreshold
        ? current?.fareAndDistance?.fare?.deadHeadThreshold
        : 0,
      deadheadFare: current?.fareAndDistance?.deadheadFare,
      baseFarePerMile: current?.fareAndDistance?.deadheadFareStructure?.values
        ?.baseFarePerMile
        ? current?.fareAndDistance?.deadheadFareStructure?.values
            ?.baseFarePerMile
        : 0,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: current?.fareAndDistance?.baseFarePerMile,
          distanceFromBaseLocation:
            current?.fareAndDistance?.distanceFromBaseLocation,
        },
        valuesFormula:
          current?.fareAndDistance?.deadheadFareStructure?.valuesFormula,
      },
      farePerMile:
        current?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      rideFare: current?.fareAndDistance?.rideFare,
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff:
            current?.fareAndDistance?.distanceFromPickToDropOff,
          farePerMile: current?.fareAndDistance?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: current?.fareAndDistance?.offHourFee,
      amount: current?.fareAndDistance?.amount,
      amountStructure: {
        values: {
          rideFare: current?.fareAndDistance?.rideFare,
          // offHourFee: current?.fareAndDistance?.offHourFee,
          baseFare: current?.fareAndDistance?.flatBaseFare,
          deadheadFare: current?.fareAndDistance?.deadheadFare,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: current?.fareAndDistance?.driverPercentage,
      driverAmount: current?.fareAndDistance?.driverAmount,
      companyAmount: current?.fareAndDistance?.companyAmount,
      ...(current?.fareAndDistance?.apiDistanceFromBaseLocation && {
        apiDistanceFromBaseLocation:
          current?.fareAndDistance?.apiDistanceFromBaseLocation,
      }),
      ...(current?.fareAndDistance?.avgDistanceFromPickToDropOff && {
        avgDistanceFromPickToDropOff:
          current?.fareAndDistance?.avgDistanceFromPickToDropOff,
      }),
      ...(current?.fareAndDistance?.useAvgDistance && {
        useAvgDistance: current?.fareAndDistance?.useAvgDistance,
      }),
    });
    if (!useFlatDriverRate) {
      setFareDetails({
        ...fareDetails,
        distanceFromBaseLocation:
          current?.fareAndDistance?.distanceFromBaseLocation,
        distanceFromPickToDropOff:
          current?.fareAndDistance?.distanceFromPickToDropOff,
        precalculatedMiles: current?.fareAndDistance?.precalculatedMiles,
        apiDistanceFromPickToDropOff:
          current?.fareAndDistance?.apiDistanceFromPickToDropOff,
        usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles,
        totalMileage: current?.fareAndDistance?.totalMileage,
        baseFare: current?.fareAndDistance?.baseFare,
        flatBaseFare: current?.fareAndDistance?.baseFare,
        baseFareStructure: {
          values: {
            flatBaseFare: current?.fareAndDistance?.baseFare,
          },
          valuesFormula: "flatBaseFare",
        },
        deadHeadThreshold: current?.fareAndDistance?.fare?.deadHeadThreshold
          ? current?.fareAndDistance?.fare?.deadHeadThreshold
          : 0,
        deadheadFare: current?.fareAndDistance?.deadheadFare,
        baseFarePerMile:
          current?.fareAndDistance?.deadheadFareStructure?.values
            ?.baseFarePerMile,
        distanceFromBaseLocation:
          current?.fareAndDistance?.distanceFromBaseLocation,
        deadheadFareStructure: {
          values: {
            baseFarePerMile: current?.fareAndDistance?.baseFarePerMile,
            distanceFromBaseLocation:
              current?.fareAndDistance?.distanceFromBaseLocation,
          },
          valuesFormula:
            current?.fareAndDistance?.deadheadFareStructure?.valuesFormula,
        },
        farePerMile:
          current?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
        distanceFromPickToDropOff:
          current?.fareAndDistance?.distanceFromPickToDropOff,
        rideFare: current?.fareAndDistance?.rideFare,
        rideFareStructure: {
          values: {
            distanceFromPickToDropOff:
              current?.fareAndDistance?.distanceFromPickToDropOff,
            farePerMile: current?.fareAndDistance?.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        },
        offHourFee: current?.fareAndDistance?.offHourFee,
        amount: current?.fareAndDistance?.amount,
        amountStructure: {
          values: {
            rideFare: current?.fareAndDistance?.rideFare,
            // offHourFee: current?.fareAndDistance?.offHourFee,
            baseFare: current?.fareAndDistance?.flatBaseFare,
            deadheadFare: current?.fareAndDistance?.deadheadFare,
          },
          // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
          valuesFormula: "rideFare + baseFare + deadheadFare",
        },
        driverPercentage: current?.fareAndDistance?.driverPercentage,
        driverAmount: current?.fareAndDistance?.driverAmount,
        companyAmount: current?.fareAndDistance?.companyAmount,
        ...(current?.fareAndDistance?.apiDistanceFromBaseLocation && {
          apiDistanceFromBaseLocation:
            current?.fareAndDistance?.apiDistanceFromBaseLocation,
        }),
        ...(current?.fareAndDistance?.avgDistanceFromPickToDropOff && {
          avgDistanceFromPickToDropOff:
            current?.fareAndDistance?.avgDistanceFromPickToDropOff,
        }),
        ...(current?.fareAndDistance?.useAvgDistance && {
          useAvgDistance: current?.fareAndDistance?.useAvgDistance,
        }),
      });
    }
  };
  useEffect(() => {
    setValues(current);
    // // old but working code below
    // setFareDetails(current.fareAndDistance);
    // setHardCodedFares({
    //   ...hardCodedFares,
    //   usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles
    //     ? current?.fareAndDistance?.usePrecalculatedMiles
    //     : hardCodedFares.usePrecalculatedMiles,
    //   precalculatedMiles: current?.fareAndDistance?.precalculatedMiles
    //     ? current?.fareAndDistance?.precalculatedMiles
    //     : hardCodedFares.precalculatedMiles,
    // });
    setHardCodedFares({
      ...hardCodedFares,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      precalculatedMiles: current?.fareAndDistance?.precalculatedMiles,
      apiDistanceFromPickToDropOff:
        current?.fareAndDistance?.apiDistanceFromPickToDropOff,
      usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles,
      totalMileage: current?.fareAndDistance?.totalMileage,
      baseFare: current?.fareAndDistance?.baseFare,
      flatBaseFare: current?.fareAndDistance?.baseFare,
      baseFareStructure: {
        values: {
          flatBaseFare: current?.fareAndDistance?.baseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadHeadThreshold: current?.fareAndDistance?.fare?.deadHeadThreshold
        ? current?.fareAndDistance?.fare?.deadHeadThreshold
        : 0,
      deadheadFare: current?.fareAndDistance?.deadheadFare,
      baseFarePerMile: current?.fareAndDistance?.deadheadFareStructure?.values
        ?.baseFarePerMile
        ? current?.fareAndDistance?.deadheadFareStructure?.values
            ?.baseFarePerMile
        : 0,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: current?.fareAndDistance?.baseFarePerMile,
          distanceFromBaseLocation:
            current?.fareAndDistance?.distanceFromBaseLocation,
        },
        valuesFormula: current.connectedReservation
          ? "(baseFarePerMile * distanceFromBaseLocation)/2"
          : "baseFarePerMile * distanceFromBaseLocation",
      },
      farePerMile:
        current?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      rideFare: current?.fareAndDistance?.rideFare,
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff:
            current?.fareAndDistance?.distanceFromPickToDropOff,
          farePerMile: current?.fareAndDistance?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: current?.fareAndDistance?.offHourFee,
      amount: current?.fareAndDistance?.amount,
      amountStructure: {
        values: {
          rideFare: current?.fareAndDistance?.rideFare,
          // offHourFee: current?.fareAndDistance?.offHourFee,
          baseFare: current?.fareAndDistance?.flatBaseFare,
          deadheadFare: current?.fareAndDistance?.deadheadFare,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: current?.fareAndDistance?.driverPercentage,
      driverAmount: current?.fareAndDistance?.driverAmount,
      companyAmount: current?.fareAndDistance?.companyAmount,
      ...(current?.fareAndDistance?.apiDistanceFromBaseLocation && {
        apiDistanceFromBaseLocation:
          current?.fareAndDistance?.apiDistanceFromBaseLocation,
      }),
      ...(current?.fareAndDistance?.avgDistanceFromPickToDropOff && {
        avgDistanceFromPickToDropOff:
          current?.fareAndDistance?.avgDistanceFromPickToDropOff,
      }),
      ...(current?.fareAndDistance?.useAvgDistance && {
        useAvgDistance: current?.fareAndDistance?.useAvgDistance,
      }),
    });
    setFareDetails({
      ...fareDetails,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      precalculatedMiles: current?.fareAndDistance?.precalculatedMiles,
      apiDistanceFromPickToDropOff:
        current?.fareAndDistance?.apiDistanceFromPickToDropOff,
      usePrecalculatedMiles: current?.fareAndDistance?.usePrecalculatedMiles,
      totalMileage: current?.fareAndDistance?.totalMileage,
      baseFare: current?.fareAndDistance?.baseFare,
      flatBaseFare: current?.fareAndDistance?.baseFare,
      baseFareStructure: {
        values: {
          flatBaseFare: current?.fareAndDistance?.baseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadHeadThreshold: current?.fareAndDistance?.fare?.deadHeadThreshold
        ? current?.fareAndDistance?.fare?.deadHeadThreshold
        : 0,
      deadheadFare: current?.fareAndDistance?.deadheadFare,
      baseFarePerMile:
        current?.fareAndDistance?.deadheadFareStructure?.values
          ?.baseFarePerMile,
      distanceFromBaseLocation:
        current?.fareAndDistance?.distanceFromBaseLocation,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: current?.fareAndDistance?.baseFarePerMile,
          distanceFromBaseLocation:
            current?.fareAndDistance?.distanceFromBaseLocation,
        },
        valuesFormula: current.connectedReservation
          ? "(baseFarePerMile * distanceFromBaseLocation)/2"
          : "baseFarePerMile * distanceFromBaseLocation",
      },
      farePerMile:
        current?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
      distanceFromPickToDropOff:
        current?.fareAndDistance?.distanceFromPickToDropOff,
      rideFare: current?.fareAndDistance?.rideFare,
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff:
            current?.fareAndDistance?.distanceFromPickToDropOff,
          farePerMile: current?.fareAndDistance?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: current?.fareAndDistance?.offHourFee,
      amount: current?.fareAndDistance?.amount,
      amountStructure: {
        values: {
          rideFare: current?.fareAndDistance?.rideFare,
          // offHourFee: current?.fareAndDistance?.offHourFee,
          baseFare: current?.fareAndDistance?.flatBaseFare,
          deadheadFare: current?.fareAndDistance?.deadheadFare,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: current?.fareAndDistance?.driverPercentage,
      driverAmount: current?.fareAndDistance?.driverAmount,
      companyAmount: current?.fareAndDistance?.companyAmount,
      ...(current?.fareAndDistance?.apiDistanceFromBaseLocation && {
        apiDistanceFromBaseLocation:
          current?.fareAndDistance?.apiDistanceFromBaseLocation,
      }),
      ...(current?.fareAndDistance?.avgDistanceFromPickToDropOff && {
        avgDistanceFromPickToDropOff:
          current?.fareAndDistance?.avgDistanceFromPickToDropOff,
      }),
      ...(current?.fareAndDistance?.useAvgDistance && {
        useAvgDistance: current?.fareAndDistance?.useAvgDistance,
      }),
    });
    setStopArr(current.rideHistory);
    if (current?.customerId?.externalCompanyId) {
      // console.log(
      //   "object",
      //   {
      //     isCompany: true,
      //     externalCompanyId: current?.customerId?.externalCompanyId,
      //   },
      //   current?.customerId?.externalCompanyId?.id
      // );
      setDetails({
        isCompany: true,
        // externalCompanyId: current?.customerId?.externalCompanyId?._id,
        externalCompanyId: current?.customerId?.externalCompanyId?._id
          ? current?.customerId?.externalCompanyId?._id
          : current?.customerId?.externalCompanyId?.id,
      });
    } else {
      setDetails({
        isCompany: false,
      });
    }
    setEta(current?.fareAndDistance?.estimatedTime);
    setAmount(current.totalAmount);
    setMil(current.totalMileage);
  }, [current]);
  // console.log("current=>", current);
  useEffect(() => {
    fareFunc();
  }, [values, current]);
  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const [updateValue, setUpdateValue] = useState(false);
  const dispatch = useDispatch();
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    fareFunc();
    // if (useHardCodedFares === false && useDirectFare === false) {
    //   calculateFareFrontEnd();
    // }
    if (updateValue) {
      delete values.driverId;
      delete values.assetId;
      delete values.customerId;
      delete values.type;
      delete values.driverBookingStatus;
      delete values.isAssigned;
      delete values.companyId;
      delete values.createdAt;
      delete values.updatedAt;
      delete values.__v;
      const id = values._id ? values._id : values.id;
      delete values._id;
      delete values.id;
      dispatch(
        updateReservation({
          ...values,
          fareAndDistance: fareDetails,
          dispatcherId: auth.user.id,
          _id: id,
        })
      );
      setUpdateValue(false);
    }
    // }
  };
  const handleUpdateValue = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    const id = values._id ? values._id : values.id;
    dispatch(
      updateReservation2(
        {
          fareAndDistance: fareDetails,
          totalAmount: parseFloat(fareDetails.amount),
          // totalAmount: parseFloat(fareDetails.driverAmount),
          dispatcherId: auth.user.id,
          _id: id,
        },
        successCB
      )
    );
  };

  // const sendQuotationViaEmail = () => {
  //   const id = values._id ? values._id : values.id;
  //   dispatch(
  //     sendQuotation({
  //       email: "ahmed.majid@iconictek.com",
  //       dispatcherId: auth.user.id,
  //       _id: id,
  //     })
  //   );
  // };
  const successCB = (updatedData) => {
    const id = values._id ? values._id : values.id;
    setFullData((prevData) => {
      const updatedIndex = prevData.findIndex(
        (item) => item._id === updatedData.id
      ); // Assuming data contains the updated item with an _id field
      // console.log(prevData, "data2");
      if (updatedIndex !== -1) {
        const newData = [...prevData];
        newData[updatedIndex] = { ...updatedData, _id: updatedData.id }; // Replace old data with updated data
        return newData;
      }
      return prevData;
    });
    socketInstance?.emit("send-reservation-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      reservationId: id,
      role: "admin",
    });
  };

  const [details, setDetails] = useState({
    isCompany: "",
    externalCompanyId: "",
  });
  // console.log(details, "detailsdetailsdetailsdetailsdetailsdetailsdetails");
  const [useHardCodedFares, setUseHardCodedFares] = useState(true);
  const [hardCodedFares, setHardCodedFares] = useState({
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationFee: "",
    additionalCharges: 0,
    deduction: 0,
    precalculatedMiles: 0,
    usePrecalculatedMiles: false,
  });
  const handleHardCodedFares = (fieldName) => (event) => {
    setHardCodedFares({
      ...hardCodedFares,
      // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
      [fieldName]: event.target.value,
    });
  };
  const [useMiles, setUseMiles] = useState(false);
  const handleUsePrecalculatedMilesTrue = () => {
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: true,
    }));
    setUseMiles(true);
  };
  const handleUsePrecalculatedMilesFalse = () => {
    // Update usePrecalculatedMiles to true
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: false,
    }));
    setUseMiles(true);
  };

  useEffect(() => {
    if (useMiles) {
      recalculate();
      setUseMiles(false);
    }
  }, [useMiles]);

  const recalculate = () => {
    calculateFareFrontEnd();
    setUpdateValue(true);
  };
  // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
  const toogleHardCoded = () => {
    if (!useHardCodedFares === true) {
      setUseDirectFare(false);
      setUseFlatDriverRate(false);
    }
    setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
  };
  const [useDirectFare, setUseDirectFare] = useState(false);
  const [directFare, setDirectFare] = useState(0);
  const toogleDirectFare = () => {
    if (!useDirectFare === true) {
      setUseHardCodedFares(false);
      setUseFlatDriverRate(false);
    }
    setUseDirectFare((useDirectFare) => !useDirectFare);
  };
  const handleDirectFare = (event) => {
    setDirectFare(event.target.value);
  };
  const fareDirectlyCalculate = () => {
    // IDHAR KAAM HOGA DRIVER AMOUNT ORIGINAL USE HO RHI HAI FARE SE NAHI
    let driverAmount = parseFloat(
      (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(directFare - driverAmount);
    // setFareDetails({
    //   distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
    //   distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
    //   totalMileage: fareDetails.totalMileage,
    //   fare: parseFloat(directFare),
    //   amount: parseFloat(directFare),
    //   estimatedTime: fareDetails.estimatedTime,
    //   etaTime: fareDetails.etaTime,
    //   driverAmount: driverAmount,
    //   companyAmount: companyAmount,
    //   baseFare: 0,
    //   rideFare: 0,
    //   offHourFee: 0,
    // });
    setFareDetails((prevFareDetails) => ({
      ...prevFareDetails,
      driverAmount: parseFloat(driverAmount),
      companyAmount: companyAmount,
    }));
    setValues({
      ...values,
      ["fareAndDistance"]: {
        ...values.fareAndDistance,
        // distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
        // distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
        // totalMileage: fareDetails.totalMileage,
        // fare: fareDetails.fare,
        // amount: fareDetails.amount,
        // estimatedTime: fareDetails.estimatedTime,
        // etaTime: fareDetails.etaTime,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
        // baseFare: 0,
        // rideFare: 0,
        // offHourFee: 0,
        // baseFare: fareDetails.baseFare,
        // rideFare: fareDetails.rideFare,
        // offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
      },
    });
    // setAmount(directFare);
    // setUpdateValue(true);
  };
  // console.log(fareDetails, "fareDetails");
  const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
  const [flatDriverRate, setFlatDriverRate] = useState(0);
  const toogleFlatDriverRate = () => {
    if (!useFlatDriverRate === true) {
      setUseHardCodedFares(false);
      setUseDirectFare(false);
    }
    setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
  };
  const handleFlatDriverRate = (event) => {
    setFlatDriverRate(event.target.value);
  };
  const flatDriverRateRecalculate = () => {
    let driverAmount = parseFloat(flatDriverRate);
    let companyAmount = parseFloat(fareDetails.amount - driverAmount);
    // console.log(driverAmount, companyAmount, "abcd");
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: fareDetails.fare,
      amount: fareDetails.amount,
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: parseFloat(driverAmount),
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
      baseFare: fareDetails.baseFare,
      rideFare: fareDetails.rideFare,
      offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
    });
    setValues({
      ...values,
      ["fareAndDistance"]: {
        distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
        distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
        totalMileage: fareDetails.totalMileage,
        fare: fareDetails.fare,
        amount: fareDetails.amount,
        estimatedTime: fareDetails.estimatedTime,
        etaTime: fareDetails.etaTime,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
        baseFare: 0,
        rideFare: 0,
        offHourFee: 0,
        baseFare: fareDetails.baseFare,
        rideFare: fareDetails.rideFare,
        offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
      },
    });
    // setAmount(directFare);
    // setUpdateValue(true);
  };
  const calculateFareFrontEnd = async () => {
    // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
    // console.log("calculateFareFrontEnd")
    const abc = values.type;
    let abcId = abc?._id ? abc?._id : abc?.id;
    let newArr = [];
    stopArr.forEach((e) => {
      newArr.push(e?.address?.address);
    });
    let obj = {};
    // yahan se new logic
    let eta = { hour: [], mins: [] };
    // yahan takh new logic
    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: newArr[0],
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        // console.log("response", response);
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text
            .replace(/,/g, "")
            .split(" mi")[0]
        );
        obj.distanceFromBaseLocation = distanceFromBaseLocation;
      })
      .catch((e) => {
        notifyFailure(e.message);
        // console.log("Directions request failed due to ", e.message);
        // window.alert("Directions request failed due to ", e)
      });
    // yahan se new logic
    let oldDate = new Date(
      stopArr[0]?.date.substring(0, 10) + "T" + stopArr[0]?.time + ":00.000Z"
    );
    // yahan takh new logic
    for (let i = 0; i < newArr.length - 1; i++) {
      await directionsService
        .route({
          origin: {
            query: newArr[i],
          },
          destination: {
            query: newArr[i + 1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          // console.log("response2", response);
          distanceFromPickToDropOff += parseFloat(
            response.routes[0].legs[0].distance.text
              .replace(/,/g, "")
              .split(" mi")[0]
          );
          // yahan se new logic
          if (response.routes[0].legs[0].duration.text.length > 7) {
            const timeString = response.routes[0].legs[0].duration.text;
            const hourRegex = /(\d+)\s*hour/;
            const minuteRegex = /(\d+)\s*min/;

            const hourMatch = hourRegex.exec(timeString);
            const minuteMatch = minuteRegex.exec(timeString);

            const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
            const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
            eta.hour.push(hours);
            eta.mins.push(minutes);

            // eta.hour.push(
            //   parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   )
            // );
            // let min =
            //   response.routes[0].legs[0].duration.text.split(" hour")[1];
            // eta.mins.push(parseInt(min.split(" mins")[0]));

            // let aaa =
            //   (parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   ) *
            //     60 +
            //     parseInt(min.split(" mins")[0])) *
            //   60 *
            //   1000;

            //old comment hai let oldDate wala
            // let oldDate = new Date(stopArr[0]?.date.substring(0, 10) + "T"+stopArr[0]?.time+":00.000Z").getTime();

            let aaa = (hours * 60 + minutes) * 60 * 1000;
            // console.log(aaa, oldDate, "eta aarha hai");
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   new Date(oldDate).toISOString(),
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          } else {
            eta.mins.push(
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              )
            );
            // let oldDate = new Date(stopArr[0]?.date.substring(0, 10) + "T"+stopArr[0]?.time+":00.000Z").getTime();
            let aaa =
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              ) *
              60 *
              1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   oldDate,
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          }
          // console.log("etaeta,", eta);
          // yahan takh new logic
          // distanceFromPickToDropOff = distanceFromPickToDropOff + parseFloat(response.routes[0].legs[0].distance.text.replace(/,/g, '').split(" mi")[0])
          // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
        })
        .catch((e) => {
          notifyFailure(e.message);
          // console.log("Directions request failed due to ", e);
          // window.alert("Directions request failed due to ", e)
        });
    }
    // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
    distanceFromBaseLocation = parseFloat(distanceFromBaseLocation.toFixed(2));
    distanceFromPickToDropOff = parseFloat(
      distanceFromPickToDropOff.toFixed(2)
    );
    obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
    if (hardCodedFares.usePrecalculatedMiles === true) {
      obj.usePrecalculatedMiles = true;
      distanceFromPickToDropOff = parseFloat(
        parseFloat(hardCodedFares.precalculatedMiles).toFixed(2)
      );
      obj.precalculatedMiles = distanceFromPickToDropOff;
    } else {
      obj.usePrecalculatedMiles = false;
    }
    totalMileage = parseFloat(
      (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
    );
    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.totalMileage = totalMileage;
    let fares = fare.fare;
    let baseFare;
    let deadheadFare = 0;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;

    if (
      details.isCompany === false ||
      details.isCompany === "" ||
      !details.isCompany
    ) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abcId) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        // console.log(
        //   e,
        //   // e.business.id === details.externalCompanyId,
        //   e.business,
        //   details.externalCompanyId
        // );
        if (e.serviceId.id === abcId) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    // console.log("serviceFare=>", serviceFare, fares, companyFare)
    let _fare;
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        // console.log(_fare)
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    // console.log("_fare=>", _fare);
    // yahan se new logic
    //  if (useHardCodedFares === true) {
    //     _fare = {
    //       flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
    //       farePerMile: parseFloat(hardCodedFares.farePerMile),
    //       deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
    //       baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
    //       totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
    //       longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
    //       offHourFee: parseFloat(hardCodedFares.offHourFee),
    //       noShowFee: parseFloat(hardCodedFares.noShowFee),
    //       cancelationFee: parseFloat(hardCodedFares.cancelationFee),
    //     };
    //   }
    // yahan takh new logic
    obj.fare = _fare;
    if (distanceFromBaseLocation <= _fare?.deadHeadThreshold) {
      baseFare = _fare?.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare?.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
      obj.deadheadFare = 0;
      obj.deadheadFareStructure = {
        values: {
          baseFarePerMile: 0,
          distanceFromBaseLocation: distanceFromBaseLocation,
        },
        valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
      };
    } else {
      // yahan se new logic
      if (_fare?.deadHeadThreshold) {
        // baseFare =
        //   _fare?.flatBaseFare +
        //   _fare?.baseFarePerMile * distanceFromBaseLocation;
        // obj.baseFare = baseFare;
        // obj.baseFareStructure = {
        //   values: {
        //     flatBaseFare: _fare?.flatBaseFare,
        //     baseFarePerMile: _fare?.baseFarePerMile,
        //     distanceFromBaseLocation: distanceFromBaseLocation,
        //   },
        //   valuesFormula:
        //     "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
        // };
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: {
            flatBaseFare: _fare.flatBaseFare,
          },
          valuesFormula: "flatBaseFare",
        };
        deadheadFare = current?.connectedReservation
          ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
          : _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.deadheadFare = current?.connectedReservation
          ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
          : _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: _fare.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula: current?.connectedReservation
            ? "(baseFarePerMile * distanceFromBaseLocation)/2"
            : "baseFarePerMile * distanceFromBaseLocation",
        };
      } else {
        baseFare = _fare?.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: { flatBaseFare: _fare?.flatBaseFare },
          valuesFormula: "flatBaseFare",
        };
        obj.deadheadFare = 0;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: 0,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
        };
      }
      // yahan takh new logic
    }
    if (distanceFromPickToDropOff <= _fare?.totalMileageAllows) {
      // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
      rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      // yahan se new logic
      if (_fare?.totalMileageAllows) {
        rideFare = distanceFromPickToDropOff * _fare?.longDistancePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            longDistancePerMile: _fare?.longDistancePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
        };
      } else {
        rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare?.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
      }
      // yahan takh
    }

    const company = auth?.user?.companyId;
    // console.log(company)
    for (let key of Object.keys(company.availability)) {
      // console.log(key, key === (moment(stopArr[0]?.date).format("dddd")  !== 'Sunday' ? moment(stopArr[0]?.date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0]?.date).endOf('week').add(-6, 'days').format('dddd')), "checkss")
      // logic added if else, first one second one added
      // if (key === (moment(stopArr[0]?.date).format("dddd")  !== 'Sunday' ? moment(stopArr[0]?.date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0]?.date).endOf('week').add(-6, 'days').format('dddd'))) {
      // }
      if (moment(stopArr[0]?.date).format("dddd") !== "Sunday") {
        if (key === moment(stopArr[0]?.date).format("dddd")) {
          if (company.availability[key] === false) {
            offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
      } else {
        if (
          key ===
          moment(stopArr[0]?.date).endOf("week").add(-6, "days").format("dddd")
        ) {
          if (company.availability[key] === false) {
            offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
      }
    }
    // console.log(company.offHours.from.substring(0,2))
    // console.log(company.offHours.from.substring(3,5))

    if (offHourFee === 0) {
      if (
        parseInt(company?.offHours?.from?.substring(0, 2)) <=
          parseInt(stopArr[0]?.time.substring(0, 2)) &&
        parseInt(stopArr[0]?.time.substring(0, 2)) <=
          parseInt(company?.offHours?.to?.substring(0, 2))
      ) {
        if (
          parseInt(company?.offHours?.from?.substring(3, 5)) <=
          parseInt(stopArr[0]?.time.substring(3, 5))
        ) {
          offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
      // else {
      //   console.log("normal")
      // }
    }

    if (offHourFee === 0) {
      amount = (rideFare + baseFare + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare + deadheadFare",
      };
      // yahan takh
    } else {
      // amount = (rideFare + baseFare + offHourFee + deadheadFare).toFixed(2);
      amount = (rideFare + baseFare + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          // offHourFee: offHourFee,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        // valuesFormula: "rideFare + baseFare + offHourFee + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      };
      // yahan takh
    }

    // yahan se new logic
    let m = 0;
    let hr = 0;
    let tempHr = 0;
    eta.mins.forEach((e) => {
      m += e;
    });
    tempHr = Math.floor(m / 60);
    m = m % 60;
    eta.hour.forEach((e) => {
      hr += e;
    });
    hr += tempHr;
    let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
    obj.estimatedTime = estimatedTime;
    let newDropOffEta = new Date(
      stopArr[0]?.date.substring(0, 10) + "T" + stopArr[0]?.time + ":00.000Z"
    ).getTime();
    // console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
    let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
    newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
    newDropOffEta = newDropOffEta.toString().split(" ")[4];
    let etaTime = newDropOffEta.substring(0, 5);
    obj.etaTime = etaTime;
    // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
    let driverAmount = !current?.connectedReservation
      ? parseFloat(
          (
            (amount - deadheadFare / 2) *
            // (auth?.user?.companyId?.driverPercentage / 100)
            (_fare?.driverPercentage / 100)
          ).toFixed(2)
        )
      : parseFloat(
          // (amount * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
          (amount * (_fare?.driverPercentage / 100)).toFixed(2)
        );
    let companyAmount = parseFloat((amount - driverAmount).toFixed(2));
    // obj.driverPercentage = auth?.user?.companyId?.driverPercentage;
    obj.driverPercentage = _fare?.driverPercentage;
    obj.driverAmount = driverAmount;
    obj.companyAmount = companyAmount;
    // console.log("objobjobjobj", obj);
    // setEta(etaTime)
    setEta(estimatedTime);
    setHardCodedFares({
      ...hardCodedFares,
      flatBaseFare: _fare?.flatBaseFare,
      farePerMile: _fare?.farePerMile,
      deadHeadThreshold: _fare?.deadHeadThreshold,
      baseFarePerMile: _fare?.baseFarePerMile,
      totalMileageAllows: _fare?.totalMileageAllows,
      longDistancePerMile: _fare?.longDistancePerMile,
      offHourFee: _fare?.offHourFee,
      noShowFee: _fare?.noShowFee,
      cancelationFee: _fare?.cancelationFee,
      distanceFromBaseLocation: obj.distanceFromBaseLocation,
      apiDistanceFromPickToDropOff: obj.apiDistanceFromPickToDropOff,
      distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
      precalculatedMiles: obj.distanceFromPickToDropOff,
      usePrecalculatedMiles: obj.usePrecalculatedMiles,
      // driverPercentage: auth?.user?.companyId?.driverPercentage,
      driverPercentage: _fare?.driverPercentage,
      deadheadFare: deadheadFare == 0 ? 0 : deadheadFare,
      baseFarePerMile: deadheadFare == 0 ? 0 : _fare.baseFarePerMile,
      deadHeadThreshold:
        !_fare?.deadHeadThreshold || _fare?.deadHeadThreshold == NaN
          ? 0
          : _fare.deadHeadThreshold,
      // additionalCharges: 0,
      // deduction: 0,
    });
    // yahan takh new logic
    setAmount(obj.amount);
    setMil(obj.distanceFromPickToDropOff);
    setFareDetails(obj);
    // yahan se new logic
    setStopArrUseApi(false);
    // yahan takh new logic
    setValues({
      ...values,
      fareAndDistance: obj,
      totalMileage: obj.distanceFromPickToDropOff,
      totalAmount: obj.amount,
      // totalAmount: obj.driverAmount,
    });
  };
  const [serviceNames, setServiceNames] = useState([]);
  // const isInitialRender = useRef(true);
  // useEffect(() => {
  //   // This code will run after the first render
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //   } else {
  //     // This code will run from the second render onwards
  //     if (serviceNames) {
  //       // Place your effect code here
  //       recalculate();
  //     }
  //   }
  // }, [serviceNames]);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const handleMouseEnter = () => {
    setShowPhoneNumber(true);
  };

  // const handleMouseLeave = () => {
  //   setShowPhoneNumber(false);
  // };
  const handleMouseLeave = () => {
    setShowPhoneNumber(true); // Show the phone number
    setTimeout(() => {
      setShowPhoneNumber(false); // Hide the phone number after 3 seconds
    }, 1000);
  };

  const handleCopyClick = () => {
    const phoneNumber = current?.driverId?.phone || "-";

    // Create a temporary input element
    const inputElement = document.createElement("input");
    inputElement.value = phoneNumber;
    document.body.appendChild(inputElement);

    // Select and copy the text inside the input element
    inputElement.select();
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(inputElement);
  }; // creating group message
  const [isTyping, setIsTyping] = useState(false);
  const chats = useSelector((state) => state.chats);
  const [chatModal, setChatModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [create, setCreate] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const users = useSelector((state) => state.users);
  const reservation = useSelector((state) => state.reservation);

  const nonDriverIds = users?.users
    ?.filter((user) => user.isDriver === false)
    ?.map((user) => user.id);

  const driverId = current?.driverId?._id
    ? current?.driverId?._id
    : current?.driverId?.id;

  const messageGroupData = Array.from(
    new Set([...nonDriverIds, ...(driverId !== undefined ? [driverId] : [])])
  );
  // const messageGroupData = [
  //   ...users.users
  //     .filter((user) => user.isDriver === false)
  //     .map((user) => user.id),
  //   reservation?.reservationDetails?.driverId?.id,
  // ];
  // console.log(messageGroupData, "messageGroupData");

  const { selectedChat, list } = chats;
  useEffect(() => {
    if (showGroupMessageDropDown === true && selectedChat) {
      // if (selectedChat) {
      dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
    }
  }, [selectedChat]);
  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("typing", (user) => setIsTyping(user));
      socketInstance.on("stop typing", (user) => setIsTyping(false));
    }
    if (socketInstance && chats.selectedChat) {
      // dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));

      socketInstance.emit("join room", chats.selectedChat.id);
    }
  }, [chats.selectedChat, socketInstance]);
  const handleInputClick = () => {
    dispatch(
      createGroupAction(
        {
          company: auth.user.companyId.id,
          chatName: current.orderId,
          isGroup: true,
          isCompanyGroup: false,
          latestMessage: null,
          groupAdmin: auth.user.id,
          groupType: "Selected Users",
          users: messageGroupData,
          // reservationId: current._id,
          reservationId: current?._id ? current?._id : current?.id,
          orderId: current.orderId,
        },
        successHandler
      )
    );
    dispatch(
      getReservationById({
        _id: current?._id ? current?._id : current?.id,
      })
    );
    setShowChat(!showChat);
    // }
  };
  const successHandler = () => {
    setCreate(false);
    setChatModal(false);
    setSelectedValue([]);
    // dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
  };

  const [showGroupMessageDropDown, setshowGroupMessageDropDown] =
    useState(false);
  const openGroupMessageDropDown = () => {
    if (showGroupMessageDropDown === true) {
      dispatch(resetState());
    } else {
      handleInputClick();
    }
    setshowGroupMessageDropDown(
      (showGroupMessageDropDown) => !showGroupMessageDropDown
    );
    // setStatus(resWork);
  };

  const foundChat =
    list.length > 0
      ? list.find(
          (x) =>
            x.reservationId &&
            (x.reservationId === current.id || x.reservationId === current._id)
        )
      : null;

  const facilityName = (id) => {
    const found = facility?.find((x) => x.id == id || x._id == id);
    return found?.name;
  };
  const normalizeServiceName = (serviceName) => {
    return serviceName
      .replace(/[_\-]/g, " ") // Replace underscores and hyphens with spaces
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim()
      .toLowerCase() // Convert to lowercase for uniformity
      .replace(/\b(\w)/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
      .replace(/\s/g, ""); // Remove all spaces
  };

  const sumRates = (typeId, serviceName) => {
    const found = fare?.clientFare?.find((x) => x.serviceId == typeId);
    if (!found) {
      return "C:$" + 0; // Return 0 if no matching fare is found
    }
    // Mapping normalized names to rate fields
    const rateFields = {
      curbtocurb: "curbToCurbRate",
      doortodoor: "doorToDoorRate",
      doorthroughdoor: "doorThroughDoorRate",
      responsibleparty: "responsiblePartyRate",
      infectiousdisease: "infectiousDiseaseRate",
    };

    let sum = 0;
    sum =
      parseFloat(found.flatBaseFare) +
      parseFloat(found.farePerMile * current?.distanceFromPickToDropOff) +
      parseFloat(found.baseFarePerMile * current?.distanceFromBaseLocation);
    if (!serviceName) {
      return "C:$" + sum?.toFixed(2);
    }
    let normalizedServiceName = normalizeServiceName(serviceName);
    //  console.log(normalizedServiceName,"normalizedServiceName")
    //  console.log(rateFields[normalizedServiceName],"normalizedServiceName")
    //  console.log(found[rateFields[normalizedServiceName]],"normalizedServiceName")

    //  if (rateFields[normalizedServiceName] && found[rateFields[normalizedServiceName]]) {
    //      sum += parseFloat(found[rateFields[normalizedServiceName]]) || 0;
    //  }

    // If multiple rate fields exist, sum them
    for (let key in rateFields) {
      if (
        normalizedServiceName.toLowerCase().includes(key) &&
        found[rateFields[key]]
      ) {
        sum += parseFloat(found[rateFields[key]]) || 0;
      }
    }

    return "C:$" + sum?.toFixed(2);
  };

  return (
    <>
      <tr
        role="row"
        style={{
          boxShadow:
            current.driverCancellation.length > 0 &&
            current.status === "Requested"
              ? "0 1px 10px 0 rgba(255, 0, 0, 0.3)" // Red shadow with 30% opacity
              : // ? "0 1px 10px 0 rgb(220, 20, 60)" // Red shadow with 30% opacity
                "", // Default shadow
        }}
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          {current?.orderId}
          &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chat-square-text-fill f-16 text-dark-grey"
            viewBox="0 0 16 16"
            onClick={() => {
              openGroupMessageDropDown();
            }}
          >
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"></path>
          </svg>
          {foundChat?.unreadCount &&
            foundChat?.unreadCount?.length > 0 &&
            foundChat.unreadCount
              .filter((x) => x.userId === auth.user.id)
              .map((val) => {
                return (
                  val.number > 0 && (
                    <span
                      className="badge bg-success text-white rounded-pill f-12 position-absolute"
                      style={{ marginLeft: "-5px", marginTop: "-6px" }}
                      data-toggle="tooltip"
                      data-original-title="Please Read New Message"
                    >
                      {val.number}
                    </span>
                  )
                );
              })}
          {current?.isOnlineBooking ? (
            <>
              <br />
              <i className="fa fa-globe" title="Quote Booking"></i> &nbsp;
              {current?.isPaid && <i className="fa fa-money" title="Paid"></i>}
              {current?.actions?.some(
                (x) => x.action === "Create:StandingOrder"
              ) && <i className="fa fa-repeat" title="Standing Order"></i>}
            </>
          ) : current?.isQuote ? (
            <>
              <br />
              <i className="fa fa-clipboard" title="Quotation"></i> &nbsp;
              {current?.isPaid && <i className="fa fa-money" title="Paid"></i>}
              {current?.actions?.some(
                (x) => x.action === "Create:StandingOrder"
              ) && <i className="fa fa-repeat" title="Standing Order"></i>}
            </>
          ) : (
            current?.actions?.some(
              (x) => x.action === "Create:StandingOrder"
            ) && (
              <>
                <br />
                <i className="fa fa-repeat" title="Standing Order"></i>
              </>
            )
          )}
          <br />
          {current?.connectedReservation &&
            current?.connectedReservation?.type +
              ":" +
              current?.connectedReservation?.reservationId?.orderId}
        </td>
        <td className="border-right">
          {current?.specialNumber
            ? current.specialNumber
            : current?.loadNumber
            ? current.loadNumber
            : ""}
        </td>
        {/* <td className="border-right">
          {current?.driverId && (
            <div>
              
              <i
                className="fa fa-edit fa-w-16 text-success"
                title="Reassigned Driver"
                onClick={openAssignDriverDropDown}
              ></i>
               {current?.driverId?.firstName}{" "}
              {current?.driverId?.lastName}
            </div>
          )}{" "}
        </td> */}
        <td className="border-right" style={{ cursor: "pointer" }}>
          {current?.driverId ? (
            <div>
              <i
                className="fa fa-edit fa-w-16 text-success"
                title="Reassigned Driver"
                onClick={openAssignDriverDropDown}
                data-toggle="modal"
                data-target="#myDriver"
              ></i>{" "}
              <span
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ textDecoration: "underline" }}
              >
                {`${current?.driverId?.firstName} ${current?.driverId?.lastName}`}
              </span>
              {showPhoneNumber && (
                <div
                  className="driver-card"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <strong>
                    Phone:{" "}
                    {current?.driverId?.phone ? current?.driverId?.phone : "-"}
                    <i
                      className="fa fa-copy"
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                      onClick={handleCopyClick}
                    >
                      {" "}
                    </i>
                  </strong>
                </div>
              )}
            </div>
          ) : (
            <>
              <i
                className="fa fa-edit fa-w-16 text-success"
                title="Reassigned Driver"
                onClick={openAssignDriverDropDown}
                data-toggle="modal"
                data-target="#myDriver"
              ></i>{" "}
              Unassigned
            </>
          )}
          {current?.assetId?.badgeId &&
            vehicle?.allBadges &&
            current?.assetId?.badgeId.map((badgeId) => {
              const matchingBadge = vehicle.allBadges.find(
                (badge) => badge.id === badgeId
              );
              return matchingBadge ? (
                <img
                  key={badgeId}
                  className="inline-block"
                  src={matchingBadge.badge}
                  alt={`Badge ${badgeId}`}
                  style={{
                    width: "25px",
                    borderRadius: "50%",
                    height: "25px",
                    objectFit: "cover",
                    marginRight: "5px",
                    marginTop: "3px",
                  }}
                />
              ) : (
                "-"
              );
            })}
        </td>
        <td className="border-right">
          {current?.assetId?.assetNo}
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">
          <strong>Pickup:</strong>{" "}
          {parseInt(current.pickUpTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current.pickUpTime.substring(0, 2)) > 12
            ? parseInt(current.pickUpTime.substring(0, 2)) - 12
            : parseInt(current.pickUpTime.substring(0, 2))}
          {":" + current.pickUpTime.substring(3, 5)}
          {parseInt(current?.pickUpTime.substring(0, 2)) > 11 ? "pm" : "am"}
          <br />
          <strong>Dropoff:</strong>{" "}
          {parseInt(current.dropOffTime.substring(0, 2)) === 0
            ? 12
            : parseInt(current.dropOffTime.substring(0, 2)) > 12
            ? parseInt(current.dropOffTime.substring(0, 2)) - 12
            : parseInt(current.dropOffTime.substring(0, 2))}
          {":" + current.dropOffTime.substring(3, 5)}
          {parseInt(current?.dropOffTime.substring(0, 2)) > 11 ? "pm" : "am"}
          <br />
          {/* 1 hrs 5 mint */}
          <strong>ETA:</strong> {current?.fareAndDistance?.estimatedTime}
        </td>
        <td className="border-right">
          <strong>
            {current?.customerId?.firstName} {current?.customerId?.lastName}
          </strong>
          {current?.customerId?.phone && (
            <>
              <br />
              {current?.customerId?.phone}
            </>
          )}
          {current?.facility && (
            <>
              <br />
              <span className="text-primary">
                {facilityName(current?.facility)}
              </span>
            </>
          )}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.pickUpTime.substring(0, 2)) > 12
                    ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                    : parseInt(current.pickUpTime.substring(0, 2))}
                  {":" + current.pickUpTime.substring(3, 5)}
                  {parseInt(current?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>{current?.distanceFromPickToDropOff?.toFixed(2)} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.dropOffDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.dropOffTime.substring(0, 2)) > 12
                    ? parseInt(current.dropOffTime.substring(0, 2)) - 12
                    : parseInt(current.dropOffTime.substring(0, 2))}
                  {":" + current.dropOffTime.substring(3, 5)}
                  {parseInt(current.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">
          <strong>{current?.type?.name}</strong>
          {current?.serviceName && (
            <i
              className="fa fa-exclamation-circle fa-w-16 text-info"
              title={current?.serviceName}
              data-toggle="modal"
              data-target="#myDriver"
              style={{ cursor: "pointer" }}
            ></i>
          )}{" "}
          <br />
          {/* <span>{current?.serviceName}</span> */}
        </td>
        <td
          className="border-right price-tag-detail"
          title="details"
          onClick={openDetailDropDown}
        >
          <strong className="price-tag-detail">
            {current.notBillable === true
              ? "Not Billable"
              : current?.fareAndDistance?.driverAmount
              ? "$" +
                parseFloat(current?.fareAndDistance?.driverAmount)?.toFixed(2)
              : "$0.00"}
            {/* $
            {current?.fareAndDistance?.driverAmount
              ? parseFloat(current?.fareAndDistance?.driverAmount)
              : 0} */}
          </strong>
          {/* {auth?.user?.permissionId?.isAdmin && (
            <>
              <br />
              <strong>
                {sumRates(
                  current?.type?._id ? current?.type?._id : current?.type?.id,
                  current?.serviceName
                )}
              </strong>
            </>
          )} */}
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">
          {/* {current?.status} */}
          {current?.status === "In Progress"
            ? current?.actions
                ?.slice()
                .reverse()
                .find(({ action }) =>
                  ["start", "arrive", "pickup"].includes(action)
                )?.action === "start"
              ? "IP: Towards Pick-up"
              : current?.actions
                  ?.slice()
                  .reverse()
                  .find(({ action }) => ["arrive", "pickup"].includes(action))
                  ?.action === "arrive"
              ? "IP: Arrived"
              : current?.actions
                  ?.slice()
                  .reverse()
                  .find(({ action }) => action === "pickup")?.action ===
                "pickup"
              ? "IP: Towards Dropoff"
              : current?.status
            : current?.status}
        </td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button
                  onClick={() =>
                    navigate(`/booking/details/${current._id}`, {
                      state: current,
                    })
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button>
                {auth?.user?.permissionId?.booking?.update === true &&
                  current?.status !== "Completed" &&
                  current?.status.toLowerCase() !== "no show" &&
                  current?.status !== "Canceled" && (
                    <>
                      <Link
                        className="dropdown-item"
                        to={`/dispatch-edit/${current._id}?return=scheduled-bookings`}
                      >
                        <i className="fa fa-edit"> Edit</i>
                      </Link>
                      {current?.isPaid !== true &&
                        (current?.isQuote === true ||
                          current?.isOnlineBooking === true) && (
                          <Link
                            className="dropdown-item"
                            // to={`/dispatch-edit/${current._id}?return=quote-bookings`}
                            // onClick={sendQuotationViaEmail}
                            onClick={openSendQuote}
                          >
                            <i className="fa fa-send"> Send Quote</i>
                          </Link>
                        )}
                    </>
                  )}

                {auth?.user?.permissionId?.booking?.update === true &&
                  current?.status !== "Completed" &&
                  current?.status.toLowerCase() !== "no show" &&
                  current?.status !== "Canceled" && (
                    <Link
                      className="dropdown-item"
                      to={`/dispatch-edit/${current._id}?returnTrip=true?return=scheduled-bookings`}
                    >
                      <i className="fa fa-edit"> Return Trip</i>
                    </Link>
                  )}

                {auth?.user?.permissionId?.booking?.update === true &&
                  current?.status !== "Canceled" && (
                    <Link
                      className="dropdown-item"
                      // to={`/dispatch-edit/${current._id}`}
                      onClick={openAssignDriverDropDown}
                    >
                      <i className="fa fa-edit"> Reassign Driver</i>
                    </Link>
                  )}
                {/* <Link
                  className="dropdown-item"
                  // to={`/dispatch-edit/${current._id}`}
                  onClick={openAssignDriverDropDown}
                >
                  <i className="fa fa-edit"> Reassign Driver</i>
                </Link> */}
                <Link
                  className="dropdown-item"
                  to={`/dispatch-edit/${current._id}?copy=true&return=scheduled-bookings`}
                >
                  <i className="fa fa-copy"> Copy</i>
                </Link>

                {auth?.user?.permissionId?.booking?.delete === true &&
                  current?.status !== "Completed" &&
                  current?.status.toLowerCase() !== "no show" &&
                  current?.status !== "Canceled" && (
                    <Link
                      className="dropdown-item delete-table-row"
                      to=""
                      data-id="20"
                      onClick={openDeclineDropDown}
                      // onClick={() => reservationDelete(current?._id)}
                    >
                      <i className="fa fa-trash"> Cancel</i>
                    </Link>
                  )}
                {auth?.user?.permissionId?.booking?.delete === true &&
                  // current?.status === "Completed" &&
                  current?.status === "Canceled" && (
                    <Link
                      className="dropdown-item delete-table-row"
                      to=""
                      data-id="20"
                      onClick={openDeleteDropDown}
                      // onClick={() => reservationDelete(current?._id)}
                    >
                      <i className="fa fa-trash"> Delete</i>
                    </Link>
                  )}
                {auth?.user?.permissionId?.booking?.delete === true &&
                  // current?.status === "Completed" &&
                  current?.status === "Canceled" && (
                    <Link
                      className="dropdown-item delete-table-row"
                      to=""
                      data-id="20"
                      onClick={openUndoDropDown}
                      // onClick={() => reservationDelete(current?._id)}
                    >
                      <i className="fa fa-undo"> Undo Cancel</i>
                    </Link>
                  )}
                {
                  auth?.user?.permissionId?.booking?.update === true && (
                    // current?.status !== "Canceled" && (
                    <Link
                      className="dropdown-item delete-table-row"
                      to=""
                      onClick={openRideLogDropDown}
                      data-id="20"
                    >
                      <i className="fa fa-address-card-o"> Ride Logs</i>
                    </Link>
                  )
                  // )
                }
                <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  onClick={openAddDispatchNote}
                  data-id="20"
                >
                  <i className="fa fa-plus"> Add Dispatch Notes</i>
                </Link>
                {auth?.user?.permissionId?.isAdmin === true && (
                  // current?.status !== "Canceled" && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={openAuditTrialDropDown}
                  >
                    <i className="fa fa-check-square-o"> Audit Trial</i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </td>

        {/* <td className="border-right">
                    <div className="select-picker"> */}
        {/* {
                            values &&
                            values.status &&
                            values.status !== "Pending" ?
                            <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.status} onChange={handleChange('status')}>
                                <option value="Unassigned">Unassigned</option>
                                <option value="Assigned">Assigned</option>
                                <option value="Pending">Pending</option>
                            </select>
                            :
                            <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.status} onChange={handleChange('status')} disabled>
                                <option value="Unassigned">Unassigned</option>
                                <option value="Assigned">Assigned</option>
                                <option value="Pending">Pending</option>
                            </select>
                        } */}
        {/* </div> */}
        {/* </td> */}
      </tr>
      <AdminBookingTodayBookingRideLogModal
        showRideLogDropDown={showRideLogDropDown}
        openRideLogDropDown={openRideLogDropDown}
        current={current}
        index={i}
      />
      {addDispatchNote && (
        <AdminAddDispatchNoteModal
          addDispatchNote={addDispatchNote}
          openAddDispatchNote={openAddDispatchNote}
          current={current}
          index={i}
        />
      )}
      {sendQuote && (
        <AdminSendQuoteModal
          sendQuote={sendQuote}
          openSendQuote={openSendQuote}
          current={current}
          index={i}
        />
      )}
      <AdminCancelBooking
        showDeclineDropDown={showDeclineDropDown}
        openDeclineDropDown={openDeclineDropDown}
        current={current}
        index={i}
        assigned={"assigned"}
        fullData={fullData}
        setFullData={setFullData}
      />{" "}
      <AdminCompleteRideDeletion
        showDeleteDropDown={showDeleteDropDown}
        openDeleteDropDown={openDeleteDropDown}
        current={current}
        index={i}
        assigned={"assigned"}
        fullData={fullData}
        setFullData={setFullData}
      />{" "}
      <AdminUndoCancelRide
        showUndoDropDown={showUndoDropDown}
        openUndoDropDown={openUndoDropDown}
        current={current}
        index={i}
        assigned={"assigned"}
        fullData={fullData}
        setFullData={setFullData}
      />{" "}
      {showAssignDriverDropDown && (
        <AdminDispatchAssignModalComponent
          showDropDown={showAssignDriverDropDown}
          openDropDown={openAssignDriverDropDown}
          current={current}
          index={i}
          assigned={"assigned"}
          fullData={fullData}
          setFullData={setFullData}
        />
      )}
      <AdminBookingAuditTrialActivityModal
        showAuditTrialDropDown={showAuditTrialDropDown}
        openAuditTrialDropDown={openAuditTrialDropDown}
        current={current}
        index={i}
        // assigned={"assigned"}
      />{" "}
      {showGroupMessageDropDown && (
        <AdminBookingGroupMessageRow
          reservation={reservation}
          auth={auth}
          showGroupMessageDropDown={showGroupMessageDropDown}
          openGroupMessageDropDown={openGroupMessageDropDown}
          current={current}
        />
      )}
      {showDetailDropDown && (
        <AdminBookingPriceDetailModal
          showDetailDropDown={showDetailDropDown}
          openDetailDropDown={openDetailDropDown}
          fareDetails={fareDetails}
          useHardCodedFares={useHardCodedFares}
          toogleHardCoded={toogleHardCoded}
          hardCodedFares={hardCodedFares}
          handleHardCodedFares={handleHardCodedFares}
          handleUsePrecalculatedMilesTrue={handleUsePrecalculatedMilesTrue}
          handleUsePrecalculatedMilesFalse={handleUsePrecalculatedMilesFalse}
          recalculate={recalculate}
          useDirectFare={useDirectFare}
          toogleDirectFare={toogleDirectFare}
          directFare={directFare}
          handleDirectFare={handleDirectFare}
          fareDirectlyCalculate={fareDirectlyCalculate}
          useFlatDriverRate={useFlatDriverRate}
          toogleFlatDriverRate={toogleFlatDriverRate}
          flatDriverRate={flatDriverRate}
          handleFlatDriverRate={handleFlatDriverRate}
          flatDriverRateRecalculate={flatDriverRateRecalculate}
          handleVeryDirectFare={handleVeryDirectFare}
          handleUpdateValue={handleUpdateValue}
        />
      )}
    </>
  );
};
export default AdminBookingFutureBookingRow;
